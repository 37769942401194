import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { SharedService } from '../shared.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-xpcn-preview-dialog',
  templateUrl: './xpcn-preview-dialog.component.html',
  styleUrls: ['./xpcn-preview-dialog.component.scss']
})
export class XpcnPreviewDialogComponent implements OnInit {

  rows = 5;
  formData: FormData = new FormData();
  addEditForm: FormGroup;
  showFreight = false;
  xpcn: any;
  invoices = [];
  waybills = [];
  invoices1 = [];
  waybills1 = [];
  invoicesCount = 0;
  waybillsCount = 0;
  rowspanheader:any=5;
  showBoxesDimensions : boolean = false

  freightMode;
  viaPoints;
  goodsDesc;
  packageType;
  riskType;
  cgst = 0;
  sgst = 0;
  igst = 0;
  invoicesTotal = 0;
  total = 0;
  totalWithourGST: number = 0;
  totalBox;
  totalWt = 0;
  showDownload = false;

  isConsignor = false;
  rows1 = 4;

  constructor(public dialogRef: MatDialogRef<XpcnPreviewDialogComponent>,    private http: HttpClient,private sanitizer: DomSanitizer,
    private _fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.addEditForm = this._fb.group({
      FileName: new FormControl(''),
      Subject: new FormControl('')
    });
  }

  invoiceHtml: SafeHtml;
    ngOnInit(): void {

      if (this.data != null) {
  
        if (this.data['xpcn'] != null) {
          this.xpcn = this.data['xpcn'];
          const url = `${environment.apiUrl}InvoiceView/PreviewLCLXPCN?ids=${this.data.xpcn.XPCNId}&isConsignor=false`;
          this.http.get(url, { responseType: 'text' }).subscribe(html => {
            this.invoiceHtml = this.sanitizer.bypassSecurityTrustHtml(html);
          });
          
        }
      }
    }
  
    close() {
      this.dialogRef.close()
    }
    downloadPDF() {
      window.open(environment.apiUrl + 'InvoiceView/DownloadLCLXPCNPDF?ids=' + this.xpcn.XPCNId+"&xpcn_code=XPCN Preview", "_blank");
    }
  
  }
  