import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { PagesService } from '../pages.service';
import { MatDialog } from '@angular/material/dialog';
import { XpcnPreviewDialogComponent } from '../shared/xpcn-preview-dialog/xpcn-preview-dialog.component';
import { AlertService } from 'src/app/shared/services/alert.service';
import { DatePipe } from '@angular/common';
import { DownloadExcelService } from 'src/app/services/download-excel.service';

@Component({
  selector: 'app-delivered',
  templateUrl: './delivered.component.html',
  styleUrls: ['./delivered.component.scss']
})
export class DeliveredComponent implements OnInit {
  filterToggle: boolean = true;
  CustomDate: boolean = false;
  filterObject: FormGroup;
  filterBy = '';
  keyword = '';
  OrderList = [];
  excelData: any[];
  public pageSize = 5;
  public currentPage = 0;
  public totalSize = 0;
  endIndex: number;
  startIndex = 1;
  keywordDate = '';
  dateFilter = '';
  filterForm: FormGroup;
  constructor(private fb: FormBuilder, public dialog: MatDialog,    public _downloadExcelService: DownloadExcelService,
    private _pageService: PagesService,
    public alertService: AlertService,  private _datePipe: DatePipe,)
   { this.filterObject = this.fb.group({
    FilterType: new FormControl(''),
    FromDate: new FormControl(''),
    ToDate: new FormControl(''),
    StartIndex: new FormControl(''),
    GridSize: new FormControl(''),
    BranchId: new FormControl(''),
    FilterBy: new FormControl(''),
    Keyword: new FormControl(''),
    CustomerId: new FormControl('')
  })
  this.filterForm = this.fb.group({
    OrderBy: new FormControl(""),
    StartIndex: new FormControl(""),
    EndIndex: new FormControl(""),
    filterBy: new FormControl(""),
    Keyword: new FormControl(""),
    FromDate: new FormControl(""),
    ToDate: new FormControl(""),
    datefilterType: new FormControl("")

  });
  this.filterForm.controls['StartIndex'].setValue(this.startIndex);
  this.filterForm.controls['EndIndex'].setValue(this.pageSize);
}

  ngOnInit(): void {
    this.filterForm.controls["datefilterType"].setValue('MTD');

    this.getData();
  }

  getData() {
    
    this._pageService.getdelivereddata(this.filterForm.value).subscribe((res) => {
      this.OrderList = res["Data"];
      if (this.OrderList != null && this.OrderList.length > 0) {
        this.totalSize = this.OrderList[0].TotalCount;
      }
    })
  }
  public handlePage(e: any, isReset = false) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    if (this.currentPage == 0) {
      this.startIndex = 1;
    } else {
      this.startIndex = (this.currentPage * this.pageSize) + 1;
    }
    this.filterForm.controls['StartIndex'].setValue(this.startIndex);
    this.filterForm.controls['EndIndex'].setValue(this.pageSize);
    this.getData();

  }
  
  openLCLXPCNPreview(XPCNID) {
    this.dialog.open(XpcnPreviewDialogComponent, {
      data: {
        xpcn: {
          XPCNId:XPCNID,
          IsFinalized : true
        }
      },
      height: 'auto',
      width: '900px',
      autoFocus: false
    });
  }
  
  clear() {
    this.startIndex = 1;
    this.pageSize = 5;
    this.currentPage = 0;
    this.totalSize = 0;
    this.filterBy = '';
    this.keyword = null;
    this.keywordDate = null;
    this.filterForm.controls['filterBy'].setValue(null);
    this.filterForm.controls['Keyword'].setValue(null);
    this.filterForm.controls["StartIndex"].setValue(this.startIndex);
    this.filterForm.controls["EndIndex"].setValue(this.pageSize);
    this.getData();
  }
  filter() {
    if (this.filterBy != '' && this.filterBy != null) {
      this.filterForm.controls['filterBy'].setValue(this.filterBy);
      if (this.filterBy != 'asndate' && this.filterBy != 'update' && this.filterBy != 'xpcndate') {
        if (this.keyword.trim() != '' && this.keyword != null) {
          this.filterForm.controls['Keyword'].setValue(this.keyword.trim());
        } else {
          this.alertService.createAlert("Please enter keyword", 0);
          return;
        }
        this.keywordDate = null;
      } else {
        this.keyword = null;
        if (this.keywordDate != '' && this.keywordDate != null) {
          this.filterForm.controls['KeywordDate'].setValue(this._datePipe.transform(this.keywordDate, 'yyyy-MM-dd'));
        } else {
          this.alertService.createAlert("Please select date", 0);
          return;
        }
      }
      this.getData();
    } else {
      this.alertService.createAlert("Please select filter by", 0);
      return;
    }
  }
  fromDate
  toDate
  dateFilterChanged(type) {

    this.filterForm.controls["datefilterType"].setValue(null);

    this.filterForm.controls["FromDate"].setValue(null);
    this.filterForm.controls["ToDate"].setValue(null);

    this.dateFilter = type;
    this.filterForm.controls["datefilterType"].setValue(this.dateFilter);

    if (type == 'Dates') {
      if (new Date(this._datePipe.transform(this.fromDate, 'yyyy-MM-dd')) > new Date(this._datePipe.transform(this.toDate, 'yyyy-MM-dd'))) {
        this.alertService.createAlert('From date cannot be grater than to date', 0);
      } else {
        this.filterForm.controls["FromDate"].setValue(new Date(this._datePipe.transform(this.fromDate, 'yyyy-MM-dd')));
        this.filterForm.controls["ToDate"].setValue(new Date(this._datePipe.transform(this.toDate, 'yyyy-MM-dd')));
      }
    } else {
      this.filterForm.controls["FromDate"].setValue(null);
      this.filterForm.controls["ToDate"].setValue(null);

      this.fromDate = null;
      this.toDate = null;
    }

    this.getData();

  }
  
  datefilterclear() {
    this.fromDate = null;
    this.toDate = null;
    this.filterForm.controls["datefilterType"].setValue('MTD');
    this.filterForm.controls["FromDate"].setValue(null);
    this.filterForm.controls["ToDate"].setValue(null);

    this.getData();
  }
  public exportAsExcel() {
    this.excelData = [];
    let list = [];

    this.filterForm.controls['EndIndex'].setValue(this.totalSize);
    this._pageService.getdelivereddata(this.filterForm.value).subscribe(data => {
      if (data != null) {
        list = data['Data'];
        if (list.length > 0) {
          list.forEach(element => {
            this.excelData.push({
              'Booking Date': this._datePipe.transform(element['BookingDate'], 'dd-MM-y'),
              'Customer Doc/LR/PO': element['loding_doc'],
              'XP Booking Id': element['BookingIdCode'],
              'Customer Name': element['Customer'],
              'Consignor Name': element['consignor'],
              'Customer Pick-up Location': element['PickUpAddress'],
              'XPCN No': element['XPCNCode'],
              'XPCN Generation Date': this._datePipe.transform(element['XPCNDate'], 'dd-MM-y'),
              'No of Packages': element['boxescount'],
              'Consignee Name': element['consignee'],
              'Consignee Location':element['consignee_location'],
              'Picked Date ':this._datePipe.transform(element['PickedDate'], 'dd-MM-y'),
              'Delivered Date ':this._datePipe.transform(element['DeliveryDate'], 'dd-MM-y'),
              'Delivery Days':element['Deliverydays'],
              'Remarks':element['pod_remark']

            })
          });
        }
        else {
          this.excelData.push({
            'Booking Date': '-',
            'Customer Doc/LR/PO': '-',
            'XP Booking Id':'-',
            'Customer Name': '-',
            'Consignor Name': '-',
            'Customer Pick-up Location': '-',
            'XPCN No' : '-',
            'XPCN Generation Date': '-',
            'No of Packages':'-',
              'Consignee Name': '-',
              'Consignee Location':'-',
              'Picked Date ':'-',
              'Delivered Date ':'-',
              'Delivery Days':'-',
              'Remarks':'-'

          })
        }
        this._downloadExcelService.exportAsExcelFile(this.excelData, 'Delivered');
      }
    })
  }
}
