import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CryptoEncriptionService {
  private static  AESEncryptionKey : string = "hdbcnsjatsodknendjfikdkcmdhdysjk";
  private static  AESIVKey : string = "kbfdrtgcvokmnhdr";

  static encryptJsonObject(jsonObject: any): string {
      const plaintext: string = JSON.stringify(jsonObject);
      const encrypted = CryptoJS.AES.encrypt(plaintext, CryptoJS.enc.Utf8.parse(this.AESEncryptionKey), {
          iv: CryptoJS.enc.Utf8.parse(this.AESIVKey),
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
      }).toString();
      return encrypted;
  }

  static encryptString(plaintext: any): string {
    const encrypted = CryptoJS.AES.encrypt(plaintext, CryptoJS.enc.Utf8.parse(this.AESEncryptionKey), {
        iv: CryptoJS.enc.Utf8.parse(this.AESIVKey),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    }).toString();
    return encrypted;
}

  static decryptString(ciphertext: string): string {
      const decrypted = CryptoJS.AES.decrypt(ciphertext, CryptoJS.enc.Utf8.parse(this.AESEncryptionKey), {
          iv: CryptoJS.enc.Utf8.parse(this.AESIVKey),
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
      });

      return CryptoJS.enc.Utf8.stringify(decrypted);
  }
}




