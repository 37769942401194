<div class="newMarginClass">
  <i class="fa fa-times" aria-hidden="true"
    style="float: right !important;margin: 0 10px 0px 0;font-size: 17px;    cursor: pointer;" (click)="close()"></i>
  <h3 mat-dialog-title>XPCN Preview </h3>
</div>
<div mat-dialog-content style="margin: 0px !important; padding: 0px !important"[innerHTML]="invoiceHtml">
</div>
<mat-dialog-actions align="end" style="padding-bottom: 10px ;margin-right: 12px;">
  <button class="btn btn-primary fa fa-download" (click)=" downloadPDF()" 
    style="cursor: pointer; left: 6px; background-color: #286ba5; color: white">
    <span style="font-family: Arial, Helvetica, sans-serif;"> Download</span>
  </button>
  <button class="btn btn-danger fa fa-times-circle" (click)="close()"
    style="margin-left: 10px ; background-color: #f44336 !important;">
    <span style="font-family: Arial, Helvetica, sans-serif;">
      Cancel</span></button>
</mat-dialog-actions>