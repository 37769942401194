import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { DownloadExcelService } from 'src/app/services/download-excel.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { MenuService } from 'src/app/theme/components/menu/menu.service';
import { TextValidator } from '../customers/text_validator';
import { PagesService } from '../pages.service';
import { AddEwaybillDialogComponent } from '../shared/add-ewaybill-dialog/add-ewaybill-dialog.component';
import { AddInvoiceDialogComponent } from '../shared/add-invoice-dialog/add-invoice-dialog.component';
import { XpcnPreviewDialogComponent } from '../shared/xpcn-preview-dialog/xpcn-preview-dialog.component';
import { CustomerserviceService } from '../customers/customerservice.service';

@Component({
  selector: 'app-prepare-xpcn',
  templateUrl: './prepare-xpcn.component.html',
  styleUrls: ['./prepare-xpcn.component.scss']
})
export class PrepareXpcnComponent implements OnInit {
  isValueEntered: boolean = false;

  customerType;
  boxDetailsReal: any[] = []
  boxesChanged = false;
  invValLessThan50 = false;
  noOfBoxes = 0;
  asnNo = '';
  invTotal = 0;
  items = [];
  access = {
    btCreate: true,
    btRead: true,
    btUpdate: true,
    btDelete: true,
  };
  xpcn = {
    //'OrderId':null,
    XPCNId: null,
  };
  public popoverTitle: string = "Confirm Delete";
  public popoverMessage: string =
    "Are you sure you want to delete this record?";

  public popoverSaveTitle: string = "Confirm Save Change";
  public popoverSaveMessage: string = "Are you sure you want to save changes?";

  addEditForm: FormGroup;
  lclXPCNId: any = -1;
  isXPCNFinalized: boolean = false;
  invoices: any[] = [];
  waybills: any[] = [];
  finalized = true;
  isMXPCN = true;
  goodsDescription: any[] = [];
  goodsDescAll: any[] = [];
  packageTypes: any[] = [];
  riskTypes: any[] = [];
  freightModes: any[] = [];
  BillingMethodList: any[] = [];
  allStatesList = [];
  statesListConsignor = [];
  statesListConsignee = [];
  districtsListConsignor = [];
  locationsListConsignor = [];
  postcodesListConsignor = [];
  districtsListConsignee = [];
  locationsListConsignee = [];
  postcodesListConsignee = [];
  CreditCode: boolean = false;
  FreightDisplaytype: boolean = true;
  total: number = 0;
  totalWithourGST: number = 0;
  rate: any = 0;
  gst: any = 0;
  CONSIGNOR: number = 1;
  CONSIGNEE: number = 2;
  consignorStateID: number = -1;
  consigneeStateID: number = -1;
  consignorDistrictID: number = -1;
  consigneeDistrictID: number = -1;
  consignorLocationID: number = -1;
  consigneeLocationID: number = -1;
  consignorPostCodeID: number = -1;
  consigneePostCodeID: number = -1;
  formData: FormData = new FormData();
  SAVE: number = 0;
  FINALIZE: number = 1;
  isSaveBoxesClicked: boolean = false;
  isFinalizeClicked: boolean = false;
  canXPXNFinalizedAllow: boolean = false;

  bookingId = "";
  originStateId: any = -1;
  originDistrictId: any = -1;
  originLocationId: any = -1;
  originPostcodeId: any = -1;

  //Backend Model
  //  public class LCLXPCNBoxes {
  //   public long? BoxId { get; set; }
  //      public long? XPCNId { get; set; }

  //      public decimal? Length { get; set; }
  //      public decimal? Breadth { get; set; }

  //      public decimal? Height { get; set; }

  //      public decimal? Weight { get; set; }

  //      public long? HUNumberId { get; set; }
  //      public string HUNumber { get; set; }
  //  }

  boxDetails: any[] = [];
  helperBoxDetails: any[] = [];
  boxesFromBackend: number = 0;
  noOfBoxesAdded: number = 0;
  SINGLE: number = 1;
  MULTIPLE: number = 2;
  isValueAddedCharges: boolean = false;
  isCODDODCheckBoxEnabled = false;
  isCODORDODChecked = true;
  isBreakBulk: boolean = false;
  deliveryRegionId: any = -1;
  chargesPaidBy: any[] = [
    { name: "FFV", value: true },
    { name: "XP", value: false },
  ];

  vacRemarksList: any[] = [];
  // [{ id: 1, name: 'Sunday Delivery', amount: 1000 },
  // { id: 2, name: 'Public Holiday', amount: 2500 },
  // { id: 3, name: 'COD', amount: 0 },
  // { id: 4, name: 'DOD', amount: 0 }]
  deliveryStateIds: string = null;
  deliveryDistrcitIds: string = null;
  deliveryLocationIds: string = null;
  deliveryStateNames: string = null;
  checkServiceType: any;
  availableWeight: any = 0;
  InputRequred: boolean;
  isBoxesCheckBox: boolean = false;
  BillingMethods: any;
  totalPkgs = 0;
  totalVWt = 0;
  totalWt = 0;



  currentDate: Date = new Date();

  invoicevalue: any = 0;
  CustomerID: any;
  fuelSurchargeApplicableTypes: any;
  sub_total:number = 0
  CustName: any;
  weightToBeConsidered: string;
  preferHigherWeight: any;
  cubicFeet: any;
  showAppointmentDate: boolean = false;

  constructor(
    public dialog: MatDialog,
    private _router: Router,
    private _activeRoute: ActivatedRoute,
    public alertService: AlertService,
    public _downloadExcelService: DownloadExcelService,
    public fb: FormBuilder,
    private _pageService: PagesService
  ) {
    this.addEditForm = this.fb.group({
      // SearchEWB: new FormControl(''),
      XPCNCode: new FormControl('', Validators.required),
      strOrderDate: new FormControl('', Validators.required),
      OrderIdCode: new FormControl('', Validators.required),
      VehicleNo: new FormControl(''),
      From: new FormControl('', Validators.required),
      To: new FormControl('', Validators.required),
      ConsignorName: new FormControl(''),
      ConsigneeName: new FormControl(''),
      GoodsDescId: new FormControl('', Validators.required),
      PackageTypeId: new FormControl('', Validators.required),
      NoOfPackages: new FormControl('', Validators.required),
      Quantity: new FormControl('', Validators.required),
      Weight: new FormControl('', Validators.required),
      NameOfConsignor: new FormControl('', Validators.required),
      ConsignorMobile: new FormControl(''),
      ConsignorEmail: new FormControl(''),
      ConsignorStateId: new FormControl('', Validators.required),
      ConsignorState: new FormControl('', Validators.required),
      ConsignorStateSearch: new FormControl(''),
      ConsignorDistrict: new FormControl('', Validators.required),
      ConsignorDistrictSearch: new FormControl(''),
      ConsignorDistrictId: new FormControl('', Validators.required),
      ConsignorLocationId: new FormControl('', Validators.required),
      ConsignorLocationSearch: new FormControl(''),
      ConsignorPostcodeId: new FormControl('', Validators.required),
      ConsignorPostcodeSearch: new FormControl(''),
      ConsignorGSTIN: new FormControl('', Validators.required),
      ConsignorAddress: new FormControl('', Validators.required),
      NameOfConsignee: new FormControl('', Validators.required),
      ConsigneeMobile: new FormControl(''),
      ConsigneeEmail: new FormControl(''),
      ConsigneeState: new FormControl('', Validators.required),
      ConsigneeStateSearch: new FormControl(''),
      ConsigneeStateId: new FormControl('', Validators.required),
      ConsigneeDistrict: new FormControl('', Validators.required),
      ConsigneeDistrictId: new FormControl('', Validators.required),
      ConsigneeLocationId: new FormControl('', Validators.required),
      ConsigneeLocationSearch: new FormControl(''),
      ConsigneePostcodeId: new FormControl('', Validators.required),
      ConsigneePostcodeSearch: new FormControl(''),
      ConsigneeGSTIN: new FormControl('', Validators.required),
      ConsigneeAddress: new FormControl('', Validators.required),
      SearchEWB: new FormControl(""),
      RiskTypeId: new FormControl('', Validators.required),
      ModeOfFreightId: new FormControl('', Validators.required),
      billingMethodId: new FormControl(''),
      CreditCode: new FormControl(''),
      OTL: new FormControl(''),
      ChargesPaidByFFV: new FormControl('', Validators.required),

      HandlingCharges: new FormControl(''),
      HaltingCharges: new FormControl(''),
      OtherCharges: new FormControl(''),
      GST: new FormControl(''),
      IsFinalized: new FormControl(false, Validators.required),
      XPCNId: new FormControl(this.lclXPCNId, Validators.required),
      ConsignorLocation: new FormControl(''),
      ConsignorPostcode: new FormControl(''),
      ConsigneeLocation: new FormControl(''),
      ConsigneePostcode: new FormControl(''),
      isVAC: new FormControl(this.isValueAddedCharges),
      VACRemarkId: new FormControl(''),
      CODBODChecked: new FormControl(true),
      VACRemarkName: new FormControl(''),
      AmountToCollect: new FormControl(''),
      ValueAddedCharge: new FormControl(''),
      UsedStickerNo: new FormControl('', Validators.required),
      ConsigneeId: new FormControl(''),
      BoxesList: new FormControl([]),
      BoxesListConsignor: new FormControl([]),
      BasicFreight: new FormControl(''),
      GSTLCL: new FormControl(''),
      SpecialRemarks: new FormControl(''),
      MinChargedWeight: new FormControl(''),
      ChargedWeight: new FormControl('', Validators.required),
      VolumetricWeight: new FormControl(''),
      LR_PO: new FormControl(''),
      ASNNo: new FormControl(''),
      Handling: new FormControl(''),
      InvValueBelow50k: new FormControl(''),

      FOVCharge: new FormControl(''),
      CNCharges: new FormControl(''),
      RiskCharges: new FormControl(''),
      ODACharge: new FormControl(''),
      ToPayCharge: new FormControl(''),
      FuelCharge: new FormControl(''),
      GreenCharge: new FormControl(''),
      HandlingCharge: new FormControl(''),
      CODDODCharge: new FormControl(''),
      DelCharge: new FormControl(''),
      Unit: new FormControl(''),
      TotalCharge: new FormControl(''),
      URPChecked: new FormControl(false),

      SubTotal: new FormControl(''),
      fuelsurchargeApplicableOn : new FormControl(''),
      RateId : new FormControl(''),

      MaxChargeableWeight : new FormControl(''),
      MaxCWAppOnXPCN : new FormControl(''),
      MinChargeableType : new FormControl(''),
      MinChargeableFreight : new FormControl(''),

      AppointmentDate:new FormControl(null),
      TotalInvoiceValue:new FormControl(null),
      IsSDA:new FormControl(null)
    });
  }

  ServiceType: any;
  ngOnInit() {
    const user = JSON.parse(localStorage.getItem('userSession'));
    this.CustomerID = user.FfvId
    this.lclXPCNId = atob(this._activeRoute.snapshot.queryParams["x"]);
    this.ServiceType = this._activeRoute.snapshot.queryParams["serviceType"];
    this.GetAllConsignee();
    this.getXPCNDetailsForFinalization(true);
    //this.disableFreightFields()
  }

  getVACReasons(codCharges: number, dodChargers: number) {
    this._pageService.getVACReasons().subscribe((res) => {
      this.vacRemarksList = res["Data"];
    });
  }

  data: any = null;
  getXPCNDetailsForFinalization(setFormControls: boolean) {
    this._pageService
      .getLCLXPCNDetailsForFinzalization(this.lclXPCNId)
      .subscribe((response) => {
        //console.log(response["Data"], "asdgjjhgdaHGDSJAHH");
        this.data = response["Data"];
        this.weightToBeConsidered = this.data['WeightToBeConsidered']
        this.preferHigherWeight = this.data['PreferHigherWeight']
        this.cubicFeet = this.data['CubicFeet']

        this.canXPXNFinalizedAllow = this.data["canXPCNFinalizeAllow"];
        this.getVACReasons(this.data["COCCharges"], this.data["DODCharges"]);
        this.bookingId = this.data["BookingId"];
        this.originStateId = this.data["OriginStateId"];
        this.originDistrictId = this.data["OriginDistrictId"];
        this.originLocationId = this.data["OriginLocationId"];
        this.originPostcodeId = this.data["OriginPostcodeId"];

        this.isBreakBulk =
          this.data["ServiceType"] === "LCL Breakbulk" ||
          this.data["ServiceType"] === "LCL Direct - Delivery";
        this.availableWeight = this.data["AvailableWeight"];
        this.deliveryRegionId = this.data["DeliveryRegionId"];
        this.goodsDescription = this.data["GoodsDescs"];
        this.goodsDescAll = this.data["GoodsDescs"];
        this.packageTypes = this.data["PackageTypes"];
        this.riskTypes = this.data["RiskTypes"];

        this.BillingMethodList = this.data["BillingMethods"];
        this.freightModes = this.data["FreightModes"];
        this.checkServiceType = this.data["ServiceType"];
        this.isXPCNFinalized = this.data["IsFinalized"];
        this.deliveryStateIds = this.data["DeliveryStateIds"];
        this.deliveryStateNames = this.data["DeliveryStateNames"];
        this.deliveryDistrcitIds = this.data["vc_delivery_district_ids"];
        this.deliveryLocationIds = this.data["vc_delivery_location_ids"];
        this.gst = this.data["GST"];
        this.getStatesList(this.data);
        this.customerType = this.data["CustomerType"];
        this.fuelSurchargeApplicableTypes = this.data["fuelSurchargeApplicableTypes"];
        this.CustName = this.data['CustomerName'];
        this.preferHigherWeight = this.data['PreferHigherWeight']

        if (this.customerType == 'LSP') {
          this.addEditForm.controls["ASNNo"].setValue(null);
          this.addEditForm.controls["ASNNo"].disable();
          this.addEditForm.controls["LR_PO"].enable();
        } else {
          this.addEditForm.controls["LR_PO"].setValue(null);
          this.addEditForm.controls["LR_PO"].disable();
          this.addEditForm.controls["ASNNo"].enable();
        }

        if (setFormControls) {
          let formControls = [
            { name: "Unit", value: this.data["Unit"] == null ? "Inch" : this.data["Unit"] },
            { name: "XPCNCode", value: this.data["XPCNCode"] },
            { name: "ASNNo", value: this.data["ASNNo"] },
            { name: "strOrderDate", value: this.data["strOrderDate"] },
            { name: "VehicleNo", value: this.data["VehicleNo"] },
            { name: "From", value: this.data["From"] },
            { name: "OrderIdCode", value: this.data["OrderIdCode"] },
            { name: "NameOfConsignor", value: this.data["ConsignorName"] },
            { name: "ConsignorGSTIN", value: this.data["ConsignorGSTIN"] },
            { name: "ConsignorAddress", value: this.data["ConsignorAddress"] },
            { name: "NameOfConsignee", value: this.data["ConsigneeName"] },
            { name: "ConsigneeGSTIN", value: this.data["ConsigneeGSTIN"] },
            { name: "ConsigneeAddress", value: this.data["ConsigneeAddress"] },
            { name: "ConsigneeId", value: this.data["ConsigneeId"] },
            { name: "ConsignorName", value: this.data["ConsignorName"] },
            { name: "ConsigneeName", value: this.data["ConsigneeName"] },
            { name: "ConsignorStateId", value: this.data["ConsignorStateId"] },
            { name: "ConsignorState", value: this.data["ConsignorState"] },
            { name: "ConsigneeStateId", value: this.data["ConsigneeStateId"] },
            { name: "ConsigneeState", value: this.data["ConsigneeState"] },
            { name: "ConsignorMobile", value: this.data["ConsignorContact"] },
            { name: "ConsignorEmail", value: this.data["ConsignorEmail"] },
            { name: "ConsigneeMobile", value: this.data["ConsigneeContact"] },
            { name: "ConsigneeEmail", value: this.data["ConsigneeEmail"] },
            { name: "GoodsDescId", value: this.data["GoodsDescId"] },
            { name: "NoOfPackages", value: this.data["NoOfPackages"] },
            { name: "Quantity", value: this.data["Quantity"] },
            { name: "Weight", value: this.data["Weight"] },
            { name: "VolumetricWeight", value: this.data["VolumetricWeight"] },
            { name: "InvValueBelow50k", value: this.data["InvValueBelow50k"] },


            { name: "CNCharges", value: this.data["CNCharges"] },
            { name: "FOVCharge", value: this.data["FOVCharge"] },
            { name: "RiskCharges", value: this.data["RiskCharges"] },
            { name: "ODACharge", value: this.data["ODACharge"] },
            { name: "ToPayCharge", value: this.data["ToPayCharge"] },
            { name: "FuelCharge", value: this.data["FuelCharge"] },
            { name: "GreenCharge", value: this.data["GreenCharge"] },
            { name: "HandlingCharges", value: this.data["HandlingCharges"] },
            { name: "CODDODCharge", value: this.data["CODDODCharge"] },
            { name: "DelCharge", value: this.data["DelCharge"] },
            { name: "TotalCharge", value: this.data["TotalCharge"] },


            {
              name: "RiskTypeId",
              value:
                this.data["RiskTypeId"] == null ? 46 : this.data["RiskTypeId"],
            },
            { name: "ModeOfFreightId", value: this.data["ModeOfFreightId"] },
            { name: "IsFinalized", value: this.data["IsFinalized"] },
            { name: "PackageTypeId", value: this.data["PackageTypeId"] },
            {
              name: "ChargesPaidByFFV",
              value:
                this.data["ChargesPaidByFFV"] == null
                  ? false
                  : this.data["ChargesPaidByFFV"],
            },
            { name: "SpecialRemarks", value: this.data["SpecialRemarks"] },
            { name: "BasicFreight", value: this.data["BasicFreight"] },
            { name: "OtherCharges", value: this.data["OtherCharges"] },
            { name: "ChargedWeight", value: this.data["xpcn_charged_weight"] },
            { name: "LR_PO", value: this.data["LR_PO"] },
            { name: "MinChargedWeight", value: this.data["MinChargedWeight"] },
            { name: "URPChecked", value: this.data["ConsigneeGSTIN"] == 'URP'? true:false },

            { name: "RateId", value: this.data["RateId"]},
            
            { name: "MaxChargeableWeight", value: this.data["MaxChargeableWeight"]},
            { name: "MaxCWAppOnXPCN", value: this.data["MaxCWAppOnXPCN"]},
            { name: "MinChargeableType", value: this.data["MinChargeableType"]},
            { name: "MinChargeableFreight", value: this.data["MinChargeableFreight"]},
            { name: "CODBODChecked", value: this.data["CODBODChecked"]}, 
            { name: "AppointmentDate", value: this.data["AppointmentDate"]},
            { name: "TotalInvoiceValue", value: this.data["TotalInvoiceValue"]},
            { name: "IsSDA", value: this.data["IsSDA"]}
          ];

const filteredBillingMethods = this.BillingMethodList.filter(
  (x) => x.LookupId == this.data["billingMethodId"]
);

if (filteredBillingMethods.length > 0) {
  this.BillingMethods = filteredBillingMethods[0].LookupName;
} else {
  console.error('No billing method found for the given billingMethodId');
  this.BillingMethods = null; 
}

this.invValChanged(this.data["InvValueBelow50k"]);
this.setValuesForAddEditFormControl(formControls);
this.setLocationDetails(this.data);
this.setVACIfExists(this.data);

          this.boxDetails = this.data['BoxesListConsignor'];

          if (this.boxDetails.length == 0 && this.addEditForm.controls['NoOfPackages'].value != null && this.addEditForm.controls['NoOfPackages'].value != '') {
            this.boxDetails.push({
              BoxId: null,
              XPCNId: this.lclXPCNId,
              Length: 0,
              Breadth: 0,
              Height: 0,
              Pkgs: Number(this.addEditForm.controls['NoOfPackages'].value),
              ActWeight: 0,
              Weight: 0,
              HUNumber: null,
              HUBarcodeUrl: null
            });
          }
        }
        this.calculateTotalPkgsWt();

        this.invoices = this.data["Invoices"];
        this.waybills = this.data["WayBills"];

        this.invoices.forEach((element) => {
          this.invoicevalue += element.InvoiceValue;
        });

        if (
          this.ConsigneeDetails.length > 0 &&
          (this.data["ConsigneeName"] != null ||
            this.data["ConsigneeName"] != "")
        ) {
          if (this.ConsigneeDetails.filter(
            (x) => x.CodeMasterName == this.data["ConsigneeName"]).length > 0) {
            let ConsigneeNameFilt = this.ConsigneeDetails.filter(
              (x) => x.CodeMasterName == this.data["ConsigneeName"]
            )[0].LookupId;
            if (ConsigneeNameFilt != null || ConsigneeNameFilt > 0) {
              this.setValuesForAddEditFormControl([
                { name: "ConsigneeId", value: ConsigneeNameFilt },
              ]);
            }
          }
        }

        // ADDED TO SHOW DEFAULT 0 BOXES - Yathish - 4 Nov

        if (this.boxDetails.length == 0 &&
          (this.addEditForm.controls['NoOfPackages'].value == null || this.addEditForm.controls['NoOfPackages'].value == '')) {
          this.boxDetails.push({
            BoxId: null,
            XPCNId: this.lclXPCNId,
            Length: 0,
            Breadth: 0,
            Height: 0,
            Pkgs: Number(this.addEditForm.controls['NoOfPackages'].value),
            ActWeight: 0,
            Weight: 0,
            HUNumber: null,
            HUBarcodeUrl: null
          });
        }

        this.lrChanged(this.addEditForm.controls['LR_PO'].value);
        this.asnChanged(this.addEditForm.controls['ASNNo'].value);

        if (this.addEditForm.controls['NameOfConsignor'].value == null || this.addEditForm.controls['NameOfConsignor'].value != '')
          this.addEditForm.controls['NameOfConsignor'].setValue(this.data["CustomerName"]);

        if (this.addEditForm.controls['ConsignorName'].value == null || this.addEditForm.controls['ConsignorName'].value != '')
          this.addEditForm.controls['ConsignorName'].setValue(this.data["CustomerName"]);

        // ADDED TO DISABLE THE FORMCONTROLS IF XPCN IS FINALIZED - Yathish - 9 Dec
        if (this.isXPCNFinalized === true)
          this.disableFinalizedXPCN()

      });
    // console.log(this.BillingMethodList, this.BillingMethods);
    this.getLCLXPCNBoxDetails();
  }

  setAddEditFormValidator(keyName: string) {
    this.addEditForm.get(keyName).setValidators(Validators.required);
    this.addEditForm.get(keyName).updateValueAndValidity();
  }

  clearAddEditFormValidator(keyName: string, clearValue: boolean) {
    this.addEditForm.get(keyName).clearValidators();
    this.addEditForm.get(keyName).updateValueAndValidity();

    if (clearValue) {
      this.setValuesForAddEditFormControl([{ name: keyName, value: null }]);
    }
  }

  setValuesForAddEditFormControl(formControls: { name: string; value: any }[]) {
    /*If fromControl keys are more than 1 then forEach executed,
     if not no need of forEach execution*/
    if (formControls.length > 1) {
      formControls.forEach((formControl) => {
        this.addEditForm.controls[formControl.name].setValue(formControl.value);
      });
    } else {
      this.addEditForm.controls[formControls[0].name].setValue(
        formControls[0].value
      );
    }
    if (this.data != null) {
      this.calculateTotal(
        // this.data["BasicFreight"],
        // this.data["ValueAddedCharge"],
        // this.gst
      );
    }
  }

  FilterDescription(value) {
    if (value === "") {
      this.goodsDescription = this.goodsDescAll;
      return;
    }
    const filterValue = value.toLowerCase();
    this.goodsDescription = this.goodsDescAll.filter((option) =>
      option.LookupName.toLowerCase().includes(filterValue)
    );
  }

  searchEwaybill() {
    this._pageService
      .checkxpcnfinalizestatus(this.lclXPCNId)
      .subscribe((res) => {
        if (!res["Data"]) {
          if (
            this.getValueOfAddEditFormControl("SearchEWB") != null &&
            this.getValueOfAddEditFormControl("SearchEWB") != ""
          ) {
            if (this.getValueOfAddEditFormControl("SearchEWB").length == 12) {
              this._pageService
                .searchLCLEWayBill(
                  this.lclXPCNId,
                  this.getValueOfAddEditFormControl("SearchEWB"),
                  this.getValueOfAddEditFormControl("ConsignorStateId")
                )
                .subscribe((response) => {
                  if (response["Success"]) {
                    this.getXPCNDetailsForFinalization(true);
                  }
                });
            } else
              this.alertService.createAlert("Enter a valid EWaybill number", 0);
          }
        } else {
          this.alertService.createAlert("XPCN already finalized", 2);
        }
      });
  }

  getValueOfAddEditFormControl(keyName: string): any {
    return this.addEditForm.controls[keyName].value;
  }

  getStatesList(data) {
    this.statesListConsignee = [];
    this.statesListConsignor = [];
    this._pageService.GetStatesDropdownlist().subscribe((response) => {
      this.allStatesList = response["Data"];
      //this.statesListConsignor = this.allStatesList

      //------------ Code to remove if no restriction of states and make statesListConsignor = this.allStatesList  -----------
      if (this.originStateId > 0) {
        let stateDetails = this.allStatesList.filter(
          (x) => x.StateId == this.originStateId
        )[0];

        this.statesListConsignor.push({
          StateId: stateDetails["StateId"],
          StateName: stateDetails["StateName"],
          StateGSTIN: stateDetails["StateGSTIN"],
        });
        if (this.statesListConsignor.length > 0) {
          this.setValuesForAddEditFormControl([
            {
              name: "ConsignorState",
              value: this.statesListConsignor[0].StateName,
            },
            {
              name: "ConsignorStateId",
              value: this.statesListConsignor[0].StateId,
            },
          ]);
        }

        if (
          this.getValueOfAddEditFormControl("ConsignorDistrictId") == null ||
          typeof this.getValueOfAddEditFormControl("ConsignorDistrictId") ==
          "undefined"
        )
          this.stateChanged(
            this.getValueOfAddEditFormControl("ConsignorStateId"),
            this.CONSIGNOR
          );
      }

      //------------------------------------------------------------------------

      //If booking type is breakbulk, restrict the states list with delivery states selected at the time of booking
      if (!this.isBreakBulk) {
        this.statesListConsignee = this.allStatesList;
      } else {
        if (this.allStatesList.length > 0) {
          let deliveryStateIdList = this.deliveryStateIds.split(",");
          let deliveryStateNameList = this.deliveryStateNames.split(",");
          if (deliveryStateIdList.length === deliveryStateNameList.length) {
            for (let i = 0; i < deliveryStateIdList.length; i++) {
              let stateDetails = this.allStatesList.filter(
                (x) => x.StateId == deliveryStateIdList[i]
              )[0];
              this.statesListConsignee.push({
                StateId: stateDetails["StateId"],
                StateName: stateDetails["StateName"],
                StateGSTIN: stateDetails["StateGSTIN"],
              });
            }
          } else {
            this.statesListConsignee = [];
          }
        }
      }
    });
  }

  stateChanged(stateId: any, type: number) {
    if (type == this.CONSIGNOR) {
      this.consignorStateID = stateId;
      let selectedState = this.statesListConsignor.filter(
        (x) => x.StateId == stateId
      )[0];
      this.setValuesForAddEditFormControl([
        { name: "ConsignorState", value: selectedState.StateName },
      ]);
      this.locationsListConsignor = null;
      this.postcodesListConsignor = null;
    } else if (type == this.CONSIGNEE) {
      this.consigneeStateID = stateId;
      let selectedState = this.statesListConsignee.filter(
        (x) => x.StateId == stateId
      )[0];
      this.setValuesForAddEditFormControl([
        { name: "ConsigneeState", value: selectedState.StateName },
      ]);
      this.locationsListConsignee = null;
      this.postcodesListConsignee = null;
    }
    this.getDistricts(stateId, type);
  }

  getDistricts(stateId: any, type: any) {
    if (stateId != null) {
      this._pageService
        .GetDistrictsDropdownlist(stateId)
        .subscribe((response) => {
          if (type === this.CONSIGNOR) {
            this.districtsListConsignor = response["Data"];

            //------------ Code to remove if no restriction of district -----------
            if (
              this.districtsListConsignor.length > 0 &&
              this.originDistrictId > 0
            ) {
              let districtDetails = this.districtsListConsignor.filter(
                (x) => x.DistrictId == this.originDistrictId
              )[0];

              this.setValuesForAddEditFormControl([
                {
                  name: "ConsignorDistrict",
                  value: districtDetails.DistrictName,
                },
                {
                  name: "ConsignorDistrictId",
                  value: districtDetails.DistrictId,
                },
              ]);

              if (
                this.getValueOfAddEditFormControl("ConsigneeLocationId") ==
                null ||
                typeof this.getValueOfAddEditFormControl(
                  "ConsigneeLocationId"
                ) == "undefined"
              ) {
                this.districtChanged(
                  this.getValueOfAddEditFormControl("ConsignorDistrictId"),
                  this.CONSIGNOR
                );
              }
            }
          }
          // else if(type == this.CONSIGNEE && this.checkServiceType === 'LCL Direct - Delivery' ){
          //   debugger
          //   let districtList = response['Data'];
          //   let deliveryDistrictIdList = this.deliveryDistrcitIds.split(',');
          //   if (deliveryDistrictIdList.length > 0) {
          //     for (let i = 0; i < deliveryDistrictIdList.length; i++) {
          //       let districtDetails = districtList.filter(x => x.DistrictId == deliveryDistrictIdList[i])[0]
          //       this.districtsListConsignee.push(districtDetails)
          //     }
          //   }
          // }
          if (
            type === this.CONSIGNEE &&
            this.checkServiceType === "LCL Direct - Delivery"
          ) {
            this.districtsListConsignee = [];
            const districtList: any[] = response["Data"];
            const deliveryDistrictIdList: string[] =
              this.deliveryDistrcitIds.split(",");

            if (deliveryDistrictIdList.length > 0) {
              for (let i = 0; i < deliveryDistrictIdList.length; i++) {
                const districtId: number = parseInt(
                  deliveryDistrictIdList[i],
                  10
                );
                const districtDetails: any | undefined = districtList.find(
                  (x) => x.DistrictId === districtId
                );
                if (districtDetails) {
                  this.districtsListConsignee.push(districtDetails);
                }
              }
            }
          } else if (
            type == this.CONSIGNEE &&
            this.checkServiceType !== "LCL Direct - Delivery"
          )
            this.districtsListConsignee = response["Data"];
        });
    }
  }

  districtChanged(districtId: any, type: number) {
    if (type === this.CONSIGNOR) {
      this.consignorDistrictID = districtId;
      this.postcodesListConsignor = null;
      this.getLocations(districtId, type);
    } else if (type == this.CONSIGNEE) {
      this.consigneeDistrictID = districtId;
      this.postcodesListConsignee = null;
      if (this.checkServiceType === "LCL Direct - Delivery")
        this.getLocations(districtId, type);
      else this.CheckGatewayAvailabiity(districtId, type, this.lclXPCNId);

      // this._pageService.CheckGatewayForDistrict(districtId).subscribe(res => {
      //   if (res['Success']) {
      //     this.getLocations(districtId, type)
      //   }
      // })
    }
    // if (type == this.CONSIGNEE) {
    //   this._pageService.CheckGatewayForDistrict(districtId).subscribe(res => {
    //     if (res['Success']) {
    //       this.getLocations(districtId, type)
    //     }
    //   })
    // }
    // else { this.getLocations(districtId, type) }
  }

  getLocations(districtId: any, type: number) {
    if (districtId != null) {
      this._pageService
        .GetLocationsDropdownlist(districtId)
        .subscribe((response) => {
          if (type === this.CONSIGNOR) {
            this.locationsListConsignor = response["Data"];

            //------------ Code to remove if no restriction of Location -----------
            if (
              this.locationsListConsignor.length > 0 &&
              this.originLocationId > 0
            ) {
              let locationDetails = this.locationsListConsignor.filter(
                (x) => x.LocationId == this.originLocationId
              )[0];

              this.setValuesForAddEditFormControl([
                {
                  name: "ConsignorLocationId",
                  value: locationDetails.LocationId,
                },
              ]);

              if (
                this.getValueOfAddEditFormControl("ConsigneePostcodeId") ==
                null ||
                typeof this.getValueOfAddEditFormControl(
                  "ConsigneePostcodeId"
                ) == "undefined"
              ) {
                this.locationChanged(
                  this.getValueOfAddEditFormControl("ConsignorLocationId"),
                  this.CONSIGNOR
                );
              }
            }
            //--------------------------------------------------------------------
          } else if (
            type == this.CONSIGNEE &&
            this.checkServiceType !== "LCL Direct - Delivery"
          ) {
            this.locationsListConsignee = response["Data"];

            if (
              this.getValueOfAddEditFormControl("ConsigneeLocationId") != null
            ) {
              this.setValuesForAddEditFormControl([
                {
                  name: "To",
                  value:
                    this.locationsListConsignee.length > 0
                      ? this.locationsListConsignee.filter(
                        (x) =>
                          x.LocationId ==
                          this.getValueOfAddEditFormControl(
                            "ConsigneeLocationId"
                          )
                      )[0].LocationName
                      : null,
                },
              ]);
            }
          } else if (
            type == this.CONSIGNEE &&
            this.checkServiceType === "LCL Direct - Delivery"
          ) {
            this.locationsListConsignee = [];
            const locationList: any[] = response["Data"];
            const deliveryLocationIdList: string[] =
              this.deliveryLocationIds.split(",");

            if (deliveryLocationIdList.length > 0) {
              for (let i = 0; i < deliveryLocationIdList.length; i++) {
                const locationtId: number = parseInt(
                  deliveryLocationIdList[i],
                  10
                );
                const locationDetails: any | undefined = locationList.find(
                  (x) => x.LocationId === locationtId
                );
                if (locationDetails) {
                  this.locationsListConsignee.push(locationDetails);
                }
              }
            }
            if (
              this.getValueOfAddEditFormControl("ConsigneeLocationId") != null
            ) {
              this.setValuesForAddEditFormControl([
                {
                  name: "To",
                  value:
                    this.locationsListConsignee.length > 0
                      ? this.locationsListConsignee.filter(
                        (x) =>
                          x.LocationId ==
                          this.getValueOfAddEditFormControl(
                            "ConsigneeLocationId"
                          )
                      )[0].LocationName
                      : null,
                },
              ]);
            }
          }
        });
    }
  }

  locationChanged(locationId: any, type: number) {
    if (type === this.CONSIGNOR) {
      this.setValuesForAddEditFormControl([
        {
          name: "From",
          value: this.locationsListConsignor.filter(
            (x) => x.LocationId == locationId
          )[0].LocationName,
        },
      ]);
    } else if (type == this.CONSIGNEE) {
      this.setValuesForAddEditFormControl([
        {
          name: "To",
          value: this.locationsListConsignee.filter(
            (x) => x.LocationId == locationId
          )[0].LocationName,
        },
      ]);
    }
    this.getPostCodes(locationId, type);
  }

  getPostCodes(locationId: any, type: number) {
    if (locationId != null) {
      this._pageService
        .GetPostcodesDropdownlist(locationId)
        .subscribe((response) => {
          if (type === this.CONSIGNOR) {
            this.postcodesListConsignor = response["Data"];
            //------------ Code to remove if no restriction of postcode -----------
            if (
              this.postcodesListConsignor.length > 0 &&
              this.originPostcodeId > 0
            ) {
              let postcodeDetails = this.postcodesListConsignor.filter(
                (x) => x.PostcodeId == this.originPostcodeId
              )[0];

              this.setValuesForAddEditFormControl([
                {
                  name: "ConsignorPostcodeId",
                  value: postcodeDetails.PostcodeId,
                },
              ]);
            }
            //--------------------------------------------------------------------
          } else if (type == this.CONSIGNEE)
            this.postcodesListConsignee = response["Data"];
        });
    }
  }

  public addEditInvoices(invoice) {
    this._pageService
      .checkxpcnfinalizestatus(this.lclXPCNId)
      .subscribe((res) => {
        if (!res["Data"]) {
          let dialogRef = this.dialog.open(AddInvoiceDialogComponent, {
            data: {
              invoice: invoice,
              xpcnId: this.lclXPCNId,
              orderDate: this.getValueOfAddEditFormControl("strOrderDate"),
            },
            height: "auto",
            width: "600px",
            autoFocus: false,
          });
          dialogRef.afterClosed().subscribe((data) => {
            if (data.isInvoiceAdded) {
              this.getXPCNDetailsForFinalization(false);
            }
          });
        } else {
          this.alertService.createAlert("XPCN already finalized", 2);
        }
      });
  }

  deleteInvoice(item) {
    this._pageService.deleteLCLXPCNInvoice(item).subscribe((res) => {
      if (res["Success"]) {
        let deletedInvoiceIndex = this.invoices.findIndex((object) => {
          return object.InvoiceId == item.InvoiceId;
        });

        this.invoices.splice(deletedInvoiceIndex, 1);
      }
    });
  }

  deleteWaybill(item) {
    this._pageService.deleteLCLXPCNWayBill(item).subscribe((res) => {
      if (res["Success"]) {
        let deletedWayBillIndex = this.waybills.findIndex((object) => {
          return object.InvoiceId == item.InvoiceId;
        });

        this.waybills.splice(deletedWayBillIndex, 1);
      }
    });
  }

  public addEditEWayBills(wayBill) {
    this._pageService
      .checkxpcnfinalizestatus(this.lclXPCNId)
      .subscribe((res) => {
        if (!res["Data"]) {
          let dialogRef = this.dialog.open(AddEwaybillDialogComponent, {
            data: {
              wayBill: wayBill,
              xpcnId: this.lclXPCNId,
              xplsId: null,
              orderDate: this.getValueOfAddEditFormControl("strOrderDate"),
            },
            height: "auto",
            width: "600px",
            autoFocus: false,
          });
          dialogRef.afterClosed().subscribe((data) => {
            if (data.isEwayBillAdded) {
              this.getXPCNDetailsForFinalization(false);
            }
          });
        } else {
          this.alertService.createAlert("XPCN already finalized", 2);
        }
      });
  }

  mobReq: boolean = false;
  onFreightChange(event) {
    // const frieghtId = this.addEditForm.controls['ModeOfFreightId'].value
    // console.log(frieghtId,'id')
    // console.log(event)
    if (event === 105) {
      this.setAddEditFormValidator("ConsigneeMobile");
      this.mobReq = true;
    } else {
      this.clearAddEditFormValidator("ConsigneeMobile", true);
      this.mobReq = false;
    }

    var isToPayServiceCharge = this.freightModes.filter(x => x.LookupId == event)[0].LookupName
    if (isToPayServiceCharge == 'To Pay' && this.ToPayServiceCharge != null && this.ToPayServiceCharge != 0) {
      this.setValuesForAddEditFormControl([{ name: "ToPayCharge", value: this.ToPayServiceCharge.toFixed(2) }])
    }else {
      this.setValuesForAddEditFormControl([{ name: "ToPayCharge", value: 0 }])
    }

    this.calculateTotal(event)

  }

  OnFreightAdd() {
    // this.rate = this.addEditForm.controls["BasicFreight"].value;
    // this.calculateTotal(
    //   this.rate,
    //   this.getValueOfAddEditFormControl("ValueAddedCharge") == null
    //     ? 0
    //     : this.getValueOfAddEditFormControl("ValueAddedCharge"),
    //   this.gst
    // );
  }

  OnOtherChargesAdd() {
    // this.calculateTotal(
    //   this.rate,
    //   this.getValueOfAddEditFormControl("ValueAddedCharge") == null
    //     ? 0
    //     : this.getValueOfAddEditFormControl("ValueAddedCharge"),
    //   this.gst
    // );
  }

  priceValidator(event) {
    return TextValidator.priceValidator(event);
  }

  calculateTotal(
    // basicFreight: number = 0,
    // vasCharges: number = 0,
    // gst: number = 0,
    ToPayValue = null
  ) {
    this.total = 0;
    let BasicFreight = this.addEditForm.controls["BasicFreight"].value == null ? 0 : this.addEditForm.controls["BasicFreight"].value;
    let CNCharges = this.addEditForm.controls["CNCharges"].value == null ? 0 : this.addEditForm.controls["CNCharges"].value;
    let FOVCharge = this.addEditForm.controls["FOVCharge"].value == null ? 0 : this.addEditForm.controls["FOVCharge"].value;
    let RiskCharges = this.addEditForm.controls["RiskCharges"].value == null ? 0 : this.addEditForm.controls["RiskCharges"].value;
    let ODACharge = this.addEditForm.controls["ODACharge"].value == null ? 0 : this.addEditForm.controls["ODACharge"].value;
    let ToPayCharge = this.addEditForm.controls["ToPayCharge"].value == null ? 0 : this.addEditForm.controls["ToPayCharge"].value;
    let FuelCharge = this.addEditForm.controls["FuelCharge"].value == null ? 0 : this.addEditForm.controls["FuelCharge"].value;
    let GreenCharge = this.addEditForm.controls["GreenCharge"].value == null ? 0 : this.addEditForm.controls["GreenCharge"].value;
    let Handling = this.addEditForm.controls["HandlingCharges"].value == null ? 0 : this.addEditForm.controls["HandlingCharges"].value;
    // let CODDODCharge = this.addEditForm.controls["CODDODCharge"].value == null ? 0 : this.addEditForm.controls["CODDODCharge"].value;
    let DelCharge = this.addEditForm.controls["DelCharge"].value == null ? 0 : this.addEditForm.controls["DelCharge"].value;
    let ValueAddedCharge = this.addEditForm.controls["ValueAddedCharge"].value == null ? 0 : this.addEditForm.controls["ValueAddedCharge"].value;
    let OtherCharges = this.addEditForm.controls["OtherCharges"].value == null ? 0 : this.addEditForm.controls["OtherCharges"].value;
    // let gst = Number(BasicFreight) * 0.12;

    this.totalWithourGST =
      Number(BasicFreight) +
      Number(CNCharges) +
      Number(FOVCharge) +
      Number(RiskCharges) +
      Number(ODACharge) +
      Number(ToPayCharge) +
      Number(FuelCharge) +
      Number(GreenCharge) +
      Number(Handling) +
      // Number(CODDODCharge) +
      Number(DelCharge) +
      Number(ValueAddedCharge) +
      Number(OtherCharges)

      this.sub_total =
      Math.round
        (Number(BasicFreight) +
          Number(CNCharges) +
          Number(Handling) +
          Number(FOVCharge) +
          Number(RiskCharges) +
          Number(ODACharge) +
          Number(ToPayCharge) +
          Number(GreenCharge) +
          Number(ValueAddedCharge)+
          Number(OtherCharges) 

        );


      
    let gst = this.totalWithourGST * (this.gst/100);

    if (this.freightModes.filter(x => x.LookupId == (ToPayValue == null ? this.getValueOfAddEditFormControl('ModeOfFreightId') : ToPayValue)).length > 0) {

      var isToPayServiceCharge = this.freightModes.filter(x => x.LookupId == (ToPayValue == null ? this.getValueOfAddEditFormControl('ModeOfFreightId') : ToPayValue))[0].LookupName
      if (isToPayServiceCharge == 'To Pay' && this.getValueOfAddEditFormControl('URPChecked') == true) {
        gst = 0
        this.addEditForm.controls["GSTLCL"].setValue(gst);
      } else {
        this.addEditForm.controls["GSTLCL"].setValue(gst);
      }
    }

    this.addEditForm.controls["GSTLCL"].setValue(gst.toFixed(2));

    this.total = Math.round(this.totalWithourGST + Number(gst.toFixed(2)));
    
    this.addEditForm.controls["TotalCharge"].setValue(this.total);
    this.addEditForm.controls["SubTotal"].setValue(this.sub_total);
  }

  finalizeXPCN(action: number) {

    if(this.rateFetched == false && action == this.FINALIZE && this.data["ServiceType"] === 'LCL Express' && this.getValueOfAddEditFormControl('RateId') == null){
      this.alertService.createAlert(
        "Please Fetch the Freight Details",
        0
      );
      //this.isSaving = false

      return
    }
    // ADDING 330 MINS TO APPOINTMENT DATE
    if(this.getValueOfAddEditFormControl('AppointmentDate') != null){
      var dt:Date = new Date(this.getValueOfAddEditFormControl('AppointmentDate'))
      this.setValuesForAddEditFormControl([{name:"AppointmentDate",value : new Date(dt.getTime() + 330 * 60 * 1000)}])
    }

    this._pageService
      .checkxpcnfinalizestatus(this.lclXPCNId)
      .subscribe((res) => {
        if (!res["Data"]) {
          this.isFinalizeClicked = true;
          this.setValuesForAddEditFormControl([
            { name: "XPCNId", value: this.lclXPCNId },
          ]);
          this.setValuesForAddEditFormControl([
            { name: "IsFinalized", value: false },
          ]);
          this.setValuesForAddEditFormControl([
            {
              name: "ConsignorName",
              value: this.getValueOfAddEditFormControl("NameOfConsignor"),
            },
          ]);
          this.setValuesForAddEditFormControl([
            {
              name: "ConsigneeName",
              value: this.getValueOfAddEditFormControl("NameOfConsignee"),
            },
          ]);

          if (this.addEditForm.controls["ConsignorLocationId"].value == null) {
            this.alertService.createAlert(
              "Please provide Consignor Location.",
              0
            );
          } else if (
            this.addEditForm.controls["ConsignorPostcodeId"].value == null
          ) {
            this.alertService.createAlert(
              "Please provide Consignor Postcode.",
              0
            );
          }
          // else if(this.addEditForm.controls['ConsignorGSTIN'].value == null){
          //   this.alertService.createAlert('Please provide Consignor GSTIN.', 0)
          // }
          //console.log(this.addEditForm.controls['ConsigneeMobile'].value)
          // else if (this.addEditForm.controls["ConsigneeMobile"].value != null) {
          //   this.clearAddEditFormValidator("ConsigneeMobile", true);
          // }

          let locationDetails = [
            {
              formControlId: "ConsignorStateId",
              formControlName: "ConsignorState",
              list: this.statesListConsignor,
              listKeyName: "StateId",
              listKeyValue: "StateName",
            },
            {
              formControlId: "ConsigneeStateId",
              formControlName: "ConsigneeState",
              list: this.statesListConsignee,
              listKeyName: "StateId",
              listKeyValue: "StateName",
            },
            {
              formControlId: "ConsignorDistrictId",
              formControlName: "ConsignorDistrict",
              list: this.districtsListConsignor,
              listKeyName: "DistrictId",
              listKeyValue: "DistrictName",
            },
            {
              formControlId: "ConsigneeDistrictId",
              formControlName: "ConsigneeDistrict",
              list: this.districtsListConsignee,
              listKeyName: "DistrictId",
              listKeyValue: "DistrictName",
            },
            {
              formControlId: "ConsignorLocationId",
              formControlName: "ConsignorLocation",
              list: this.locationsListConsignor,
              listKeyName: "LocationId",
              listKeyValue: "LocationName",
            },
            {
              formControlId: "ConsigneeLocationId",
              formControlName: "ConsigneeLocation",
              list: this.locationsListConsignee,
              listKeyName: "LocationId",
              listKeyValue: "LocationName",
            },
            {
              formControlId: "ConsignorPostcodeId",
              formControlName: "ConsignorPostcode",
              list: this.postcodesListConsignor,
              listKeyName: "PostcodeId",
              listKeyValue: "PostcodeName",
            },
            {
              formControlId: "ConsigneePostcodeId",
              formControlName: "ConsigneePostcode",
              list: this.postcodesListConsignee,
              listKeyName: "PostcodeId",
              listKeyValue: "PostcodeName",
            },
          ];

          locationDetails.forEach((data) => {
            this.setFormControlNameWithId(
              data.formControlId,
              data.formControlName,
              data.list,
              data.listKeyName,
              data.listKeyValue
            );
          });

          this.formData.delete("FormData");

          if (
            this.isBreakBulk &&
            this.getValueOfAddEditFormControl("Weight") != null &&
            this.getValueOfAddEditFormControl("Weight") > this.availableWeight
          ) {
            this.alertService.createAlert(
              `Weight is exceeding the payload.\n  Available Weight is ${this.availableWeight} Kgs`,
              0
            );

            return;
          }

          this.addEditForm.controls['UsedStickerNo'].setValue(this.addEditForm.controls['NoOfPackages'].value);

          // ADDED TO CHECK THE DUPLICATE BOX DIMENSIONS
          if (this.hasDuplicateDimensions(this.boxDetails)) {
            this.alertService.createAlert(
              "Duplicate Box Dimensions are not allowed",
              0
            );
            //this.isSaving = false
            return;
          }
          

          if (this.boxesChanged == true) {
            this.boxDetailsReal = [];
            this.boxDetails.forEach(x => {
              for (let a = x.Pkgs; a > 0; a--) {
                let box = {
                  BoxId: null,
                  XPCNId: this.lclXPCNId,
                  Length: x.Length,
                  Breadth: x.Breadth,
                  Height: x.Height,
                  Pkgs: 1,
                  ActWeight: Math.round(Number(x.ActWeight / x.Pkgs)),
                  Weight: Math.round(Number(x.Weight / x.Pkgs)),
                  isSelected: true,
                }

                this.boxDetailsReal.push(box);
              }
            });
          }

          //  const invalid = [];
          //  const controls = this.addEditForm.controls;
          //  for (const name in controls) {
          //      if (controls[name].invalid) {
          //          invalid.push(name);
          //      }
          //  }
          // console.log(invalid)

          var isToPayServiceCharge = this.freightModes.filter(x => x.LookupId == this.getValueOfAddEditFormControl('ModeOfFreightId'))[0].LookupName

          if ((this.addEditForm.controls["BasicFreight"].value == null || this.addEditForm.controls["BasicFreight"].value == 0) && isToPayServiceCharge != 'Credit') {
            this.alertService.createAlert("Base Freight is required", 0);
            return;
          }
          if (this.addEditForm.valid) {
            if (action == this.FINALIZE) {
              // if (this.addEditForm.controls["ConsigneeMobile"].value != null) {
              //   this.clearAddEditFormValidator("ConsigneeMobile", true);
              // }
              // if (
              //   this.addEditForm.controls["UsedStickerNo"].value == null ||
              //   this.addEditForm.controls["UsedStickerNo"].value == ""
              // ) {
              //   this.InputRequred = true;
              //   this.alertService.createAlert(
              //     "Quantity of used sticker not suplied",
              //     0
              //   );
              //   return;
              // }
              // if (
              //   this.addEditForm.controls["UsedStickerNo"].value !=
              //   this.boxDetailsArr.length
              // ) {
              //   this.alertService.createAlert(
              //     "Selected quantity is not matching with used quantity  (" +
              //     this.addEditForm.controls["UsedStickerNo"].value +
              //     "/" +
              //     this.boxDetailsArr.length +
              //     ")",
              //     0
              //   );
              //   return;
              // }

              if (!this.gstinValidation()) {
                this.alertService.createAlert(
                  "GISTIN not match with selected state ",
                  0
                );
                return;
              }
              if ((this.getValueOfAddEditFormControl('ModeOfFreightId') == 104 || this.getValueOfAddEditFormControl('ModeOfFreightId') == 105) && (this.getValueOfAddEditFormControl('BasicFreight') <= 0 || this.getValueOfAddEditFormControl('BasicFreight') == null)) {
                this.alertService.createAlert("Basic Freight required for Paid/To-Pay billing.", 0);
                return;
              }

              if (this.addEditForm.controls["InvValueBelow50k"].value == null) {
                this.alertService.createAlert('Please select Invoice Value Below Rs. 50K.', 0);
                return;
              } else if (this.waybills.length <= 0 && this.addEditForm.controls["InvValueBelow50k"].value != true &&
              this.invoices.reduce(
                (sum, item) => sum + Number(item.InvoiceValue),
                0
              ) > 50000) {
                this.alertService.createAlert("Ewaybill required", 0);
                return;
              }

              //&& Number(this.invoices['InvoiceValue']) > 49999
              // if (
              //   this.waybills.length <= 0 &&
              //   this.invoices.reduce(
              //     (sum, item) => sum + Number(item.InvoiceValue),
              //     0
              //   ) > 50000
              // ) {
              //   this.alertService.createAlert("Ewaybill required ", 0);
              //   return;
              // }

              this.setValuesForAddEditFormControl([
                { name: "IsFinalized", value: true },
              ]);
              this.formData.append(
                "FormData",
                JSON.stringify(this.addEditForm.value)
              );
              // let huNotGeneratedBoxes = this.boxDetails.filter(
              //   (x) => x.HUNumber == null
              // ).length;
              let totalPkgs = 0;
              let chargedWeight = 0;
              if (this.boxDetails.length == 0) {
                this.alertService.createAlert("Please Add Boxes", 0);
                return;
              } else if (this.boxDetails.filter(x => Number(x.Length) == 0 || Number(x.Breadth) == 0 || Number(x.Height) == 0 || Number(x.Pkgs) == 0).length > 0) {
                this.alertService.createAlert('Length, Breadth, Height and PkKGS of all Docket Items must be greater than 0.', 0);
                return;
              } else {
                this.boxDetails.forEach(x => {
                  totalPkgs += Number(x.Pkgs);
                  chargedWeight += Number(x.Weight);
                });

                let actWeight = Number(this.addEditForm.controls['Weight'].value);
                let minChargedWt = this.addEditForm.controls['MinChargedWeight'].value;

                this.addEditForm.controls['ChargedWeight'].setValue(Math.max(actWeight, this.totalVWt, minChargedWt));

                // this.addEditForm.controls['ChargedWeight'].setValue(actWeight > chargedWeight ? actWeight : chargedWeight);

                if (totalPkgs != Number(this.addEditForm.controls['NoOfPackages'].value)) {
                  this.alertService.createAlert("Boxes must match sum of PKGS Docket Items", 0);
                  return;
                }
              }
              
              if (this.canXPXNFinalizedAllow == false) {
                this.alertService.createAlert(
                  "XPCN cannot be finalized for future bookings",
                  0
                );
                return;
              }

              /* ENABLING THE FREIGHT FIELDS HERE */
              this.enableFreightFields()
              
              if (this.boxesChanged == true) {
                this.boxesChanged = false;
                this._pageService.upsertLCLOrderXPCNBoxesAll({ "LCLXPCNBoxes": this.boxDetailsReal }).subscribe(res => {
                  this.isSaveBoxesClicked = false
                  let responseStatus = res['Success']
                  // this.alertService.createAlert(res['Message'], responseStatus == true ? 1 : 0)
                  if (responseStatus) {
                    this._pageService.getLCLOrderXPCNBoxes(this.lclXPCNId).subscribe(res => {
                      this.boxDetailsReal = res['Data'];
                      this.boxDetailsReal.forEach(x => {
                        x.isSelected = true
                      });
                      this.addEditForm.controls['BoxesListConsignor'].setValue(this.boxDetails);
                      this.addEditForm.controls['BoxesList'].setValue(this.boxDetailsReal);

                      // ADDED THIS CODE TO CALCULATE THE SUM OF ALL INVOICES AND SAVE IT
                      var TotalinvoiceValue = 0
                      if (this.invoices.length > 0) {
                        this.invoices.forEach((element) => {
                          TotalinvoiceValue += element.InvoiceValue;
                        });

                        this.setValuesForAddEditFormControl([
                          { name: "TotalInvoiceValue", value: TotalinvoiceValue }
                        ]);
                      }
                      //-------------------------------------------------------------------


                      this.formData.delete('FormData');
                      this.formData.append('FormData', JSON.stringify(this.addEditForm.value));
                      this._pageService.finalizeLCLXPCN(this.formData).subscribe(res => {
                        if (res['Success']) {

                          this.getXPCNDetailsForFinalization(true);
                          this.getLCLXPCNBoxDetails();
                          // this.getLCLXPCNBoxDetails();
                          this.isXPCNFinalized = action === this.FINALIZE ? true : false;
                          // this.alertService.createAlert(res['Message'], 1)
                        }
                        else {
                          // this.alertService.createAlert(res['Message'], 0)
                        }

                        /* DISABLING THE FREIGHT FIELDS HERE */
                        this.disableFreightFields()
                      })
                    })
                  }
                });
              } else {
                this._pageService.getLCLOrderXPCNBoxes(this.lclXPCNId).subscribe(res => {
                  this.boxDetailsReal = res['Data'];
                  this.boxDetailsReal.forEach(x => {
                    x.isSelected = true
                  });
                  this.addEditForm.controls['BoxesListConsignor'].setValue(this.boxDetails);
                  this.addEditForm.controls['BoxesList'].setValue(this.boxDetailsReal);

                  // ADDED THIS CODE TO CALCULATE THE SUM OF ALL INVOICES AND SAVE IT
                  var TotalinvoiceValue = 0
                  if (this.invoices.length > 0) {
                    this.invoices.forEach((element) => {
                      TotalinvoiceValue += element.InvoiceValue;
                    });

                    this.setValuesForAddEditFormControl([
                      { name: "TotalInvoiceValue", value: TotalinvoiceValue }
                    ]);
                  }
                  //-------------------------------------------------------------------

                  this.formData.delete('FormData');
                  this.formData.append('FormData', JSON.stringify(this.addEditForm.value));
                  this._pageService.finalizeLCLXPCN(this.formData).subscribe(res => {
                    if (res['Success']) {

                      this.getXPCNDetailsForFinalization(true);
                      this.getLCLXPCNBoxDetails();
                      // this.getLCLXPCNBoxDetails();
                      this.isXPCNFinalized = action === this.FINALIZE ? true : false;
                      // this.alertService.createAlert(res['Message'], 1)
                    }
                    else {
                      // this.alertService.createAlert(res['Message'], 0)
                    }
                    /* DISABLING THE FREIGHT FIELDS HERE */
                    this.disableFreightFields()
                  })
                })
                // this._pageService.finalizeLCLXPCN(this.formData).subscribe((res) => {
                //   if (res["Success"]) {
                //     this.isXPCNFinalized = action === this.FINALIZE ? true : false;
                //     // this.alertService.createAlert(res["Message"], 1);
                //     if (action == this.FINALIZE) {
                //       this.getLCLXPCNBoxDetails();
                //     }
                //   } else {
                //     // this.alertService.createAlert(res["Message"], 0);
                //   }
                // });
              }

              //  else {
              //   let huNotGeneratedBoxes = this.boxDetails.filter(
              //     (x) => x.HUNumber == null
              //   ).length;
              //   if (huNotGeneratedBoxes > 0) {
              //     this.alertService.createAlert("Please Save Boxes", 0);
              //     return;
              //   }
              //   this.formData.append("FormData", JSON.stringify(this.addEditForm.value));
              // }
            } else {
              let totalPkgs = 0;
              let chargedWeight = 0;
              if (this.boxDetails.length == 0) {
                this.alertService.createAlert("Please Add Boxes", 0);
                return;
              } else if (this.boxDetails.filter(x => Number(x.Length) == 0 || Number(x.Breadth) == 0 || Number(x.Height) == 0 || Number(x.Pkgs) == 0).length > 0) {
                this.alertService.createAlert('Length, Breadth, Height and PkKGS of all Docket Items must be greater than 0.', 0);
                return;
              } else {
                this.boxDetails.forEach(x => {
                  totalPkgs += Number(x.Pkgs);
                  chargedWeight += Number(x.Weight);
                });

                let actWeight = Number(this.addEditForm.controls['Weight'].value);
                let minChargedWt = this.addEditForm.controls['MinChargedWeight'].value;

                this.addEditForm.controls['ChargedWeight'].setValue(Math.max(actWeight, this.totalVWt, minChargedWt));
                // this.addEditForm.controls['ChargedWeight'].setValue(actWeight > chargedWeight ? actWeight : chargedWeight);

                if (totalPkgs != Number(this.addEditForm.controls['NoOfPackages'].value)) {
                  this.alertService.createAlert("Boxes must match sum of PKGS Docket Items", 0);
                  return;
                }
              }
              this.formData.append("FormData", JSON.stringify(this.addEditForm.value));
              if (action == this.SAVE) {

                /* ENABLING THE FREIGHT FIELDS HERE */
                this.enableFreightFields()

                if (this.boxesChanged == true) {
                  this.boxesChanged = false;
                  this._pageService.upsertLCLOrderXPCNBoxesAll({ "LCLXPCNBoxes": this.boxDetailsReal }).subscribe(res => {
                    this.isSaveBoxesClicked = false
                    let responseStatus = res['Success']
                    // this.alertService.createAlert(res['Message'], responseStatus == true ? 1 : 0)
                    if (responseStatus) {
                      this._pageService.getLCLOrderXPCNBoxes(this.lclXPCNId).subscribe(res => {
                        this.boxDetailsReal = res['Data'];
                        this.boxDetailsReal.forEach(x => {
                          x.isSelected = true
                        });
                        this.addEditForm.controls['BoxesListConsignor'].setValue(this.boxDetails);
                        this.addEditForm.controls['BoxesList'].setValue(this.boxDetailsReal);
                        this.formData.delete('FormData');
                        this.formData.append('FormData', JSON.stringify(this.addEditForm.value));
                        this._pageService.finalizeLCLXPCN(this.formData).subscribe(res => {
                          if (res['Success']) {

                            this.getXPCNDetailsForFinalization(true);
                            this.getLCLXPCNBoxDetails();
                            // this.getLCLXPCNBoxDetails();
                            this.isXPCNFinalized = action === this.FINALIZE ? true : false;
                            // this.alertService.createAlert(res['Message'], 1)
                          }
                          else {
                            // this.alertService.createAlert(res['Message'], 0)
                          }
                        })
                      })
                    }
                  });
                } else {
                  this._pageService.getLCLOrderXPCNBoxes(this.lclXPCNId).subscribe(res => {
                    this.boxDetailsReal = res['Data'];
                    this.boxDetailsReal.forEach(x => {
                      x.isSelected = true
                    });
                    this.addEditForm.controls['BoxesListConsignor'].setValue(this.boxDetails);
                    this.addEditForm.controls['BoxesList'].setValue(this.boxDetailsReal);
                    this.formData.delete('FormData');
                    this.formData.append('FormData', JSON.stringify(this.addEditForm.value));
                    this._pageService.finalizeLCLXPCN(this.formData).subscribe(res => {
                      if (res['Success']) {

                        this.getXPCNDetailsForFinalization(true);
                        this.getLCLXPCNBoxDetails();
                        // this.getLCLXPCNBoxDetails();
                        this.isXPCNFinalized = action === this.FINALIZE ? true : false;
                        // this.alertService.createAlert(res['Message'], 1)
                      }
                      else {
                        // this.alertService.createAlert(res['Message'], 0)
                      }
                    })
                  })
                  // this._pageService.finalizeLCLXPCN(this.formData).subscribe((res) => {
                  //   if (res["Success"]) {
                  //     // if (action == this.SAVE)
                  //     //   this.ValidateEnterBoxes(this.METHOD_CALL);
                  //     // this.alertService.createAlert(res["Message"], 1);
                  //   } else {
                  //     // this.alertService.createAlert(res["Message"], 0);
                  //   }
                  // });
                }
              }
            }
          } else {
            // console.log(this,this.addEditForm.errors);
            // console.log(this.addEditForm.controls, "Finalize");
            // console.log(this.addEditForm.controls['LR_PO'].value, 'LR/PO')
            this.alertService.createAlert(
              "Please fill all required details",
              0
            );
          } 
        } else {
          this.alertService.createAlert("XPCN already finalized", 2);
        }
      });
  }

  onSaveXPCN() {

    // ADDING 330 MINS TO APPOINTMENT DATE
    if(this.getValueOfAddEditFormControl('AppointmentDate') != null){
      var dt:Date = new Date(this.getValueOfAddEditFormControl('AppointmentDate'))
      this.setValuesForAddEditFormControl([{name:"AppointmentDate",value : new Date(dt.getTime() + 330 * 60 * 1000)}])
    }

    const v = [
      { id: 'ConsignorDistrictId', name: 'Consignor District' },
      { id: 'ConsigneeDistrictId', name: 'Consignee District' },

      { id: 'ConsignorLocationId', name: 'Consignor Loaction' },
      { id: 'ConsigneeLocationId', name: 'Consignee Location' },

      { id: 'ConsignorPostcodeId', name: 'Consignor Postcode' },
      { id: 'ConsigneePostcodeId', name: 'Consignee Postcode' },

      { id: 'ConsignorStateId', name: 'Consignor State' },
      { id: 'ConsigneeStateId', name: 'Consignee State' }
    ]

    this._pageService
      .checkxpcnfinalizestatus(this.lclXPCNId)
      .subscribe((res) => {

        if (!res["Data"]) {

          if (
            this.isBreakBulk &&
            this.getValueOfAddEditFormControl("Weight") != null &&
            this.getValueOfAddEditFormControl("Weight") > this.availableWeight
          ) {
            this.alertService.createAlert(
              `Weight is exceeding the payload.\n  Available Weight is ${this.availableWeight} Kgs`,
              0
            );

            return;
          }

          this.setValuesForAddEditFormControl([
            { name: "XPCNId", value: this.lclXPCNId },
          ]);
          this.setValuesForAddEditFormControl([
            { name: "IsFinalized", value: false },
          ]);
          this.setValuesForAddEditFormControl([
            {
              name: "ConsignorName",
              value: this.getValueOfAddEditFormControl("NameOfConsignor"),
            },
          ]);
          this.setValuesForAddEditFormControl([
            {
              name: "ConsigneeName",
              value: this.getValueOfAddEditFormControl("NameOfConsignee"),
            },
          ]);

          let validationFailed = false;

          // CHECKING THE MANDATORY FIELDS FOR SAVE XPCN
          v.forEach(element => {
            if (this.getValueOfAddEditFormControl(element.id) == null) {
              this.alertService.createAlert(
                `Please provide ${element.name}.`,
                0
              );

              validationFailed = true;
              return;
            }
          });

          if (validationFailed) {
            return; // Exit the function
          }

          this.setlocationDetailsOnSave()

          if (this.getValueOfAddEditFormControl("ConsigneeGSTIN") == 'URP') {
            this.EnableFormControls([{ name: 'ConsigneeGSTIN'}])
          }

          let formcontrolsToEnableDisable = [
            { name: "BasicFreight", value: 0 },
            { name: "FOVCharge", value: 0 },
            { name: "RiskCharges", value: 0 },
            { name: "ODACharge", value: 0 },
            { name: "FuelCharge", value: 0 },
            { name: "GreenCharge", value: 0 },
            { name: "HandlingCharges", value: 0 },
            { name: "OtherCharges", value: 0 },
            { name: "ToPayCharge", value: 0 },
            { name: "CNCharges", value: 0 }
          ]

          this.EnableFormControls(formcontrolsToEnableDisable)

          // ADDED TO CHECK THE DUPLICATE BOX DIMENSIONS
          if (this.hasDuplicateDimensions(this.boxDetails)) {
            this.alertService.createAlert(
              "Duplicate Box Dimensions are not allowed",
              0
            );
            //this.isSaving = false
            return;
          }


          if (this.boxesChanged == true) {
            this.boxDetailsReal = [];
            this.boxDetails.forEach(x => {
              for (let a = x.Pkgs; a > 0; a--) {
                let box = {
                  BoxId: null,
                  XPCNId: this.lclXPCNId,
                  Length: x.Length,
                  Breadth: x.Breadth,
                  Height: x.Height,
                  Pkgs: 1,
                  ActWeight: Math.round(Number(x.ActWeight / x.Pkgs)),
                  Weight: Math.round(Number(x.Weight / x.Pkgs)),
                  isSelected: true,
                }

                this.boxDetailsReal.push(box);
              }
            });

            this.boxesChanged = false;
            this._pageService.upsertLCLOrderXPCNBoxesAll({ "LCLXPCNBoxes": this.boxDetailsReal }).subscribe(res => {
              this.isSaveBoxesClicked = false
              let responseStatus = res['Success']
              if (responseStatus) {
                this._pageService.getLCLOrderXPCNBoxes(this.lclXPCNId).subscribe(res => {
                  this.boxDetailsReal = res['Data'];
                  this.boxDetailsReal.forEach(x => {
                    x.isSelected = true
                  });
                  this.addEditForm.controls['BoxesListConsignor'].setValue(this.boxDetails);
                  this.addEditForm.controls['BoxesList'].setValue(this.boxDetailsReal);

                  // ADDED THIS CODE TO CALCULATE THE SUM OF ALL INVOICES AND SAVE IT
                  var TotalinvoiceValue = 0
                  if (this.invoices.length > 0) {
                    this.invoices.forEach((element) => {
                      TotalinvoiceValue += element.InvoiceValue;
                    });

                    this.setValuesForAddEditFormControl([
                      { name: "TotalInvoiceValue", value: TotalinvoiceValue }
                    ]);
                  }
                  //-------------------------------------------------------------------

                  this.formData.delete('FormData');
                  this.formData.append('FormData', JSON.stringify(this.addEditForm.value));
                  this._pageService.finalizeLCLXPCN(this.formData).subscribe(res => {
                    if (res['Success']) {

                      this.getXPCNDetailsForFinalization(true);
                      this.getLCLXPCNBoxDetails();

                      this.isXPCNFinalized = false;
                    }

                  })
                })
              }
            });
          }
          else {
            this._pageService.getLCLOrderXPCNBoxes(this.lclXPCNId).subscribe(res => {
              this.boxDetailsReal = res['Data'];
              this.boxDetailsReal.forEach(x => {
                x.isSelected = true
              });
              this.addEditForm.controls['BoxesListConsignor'].setValue(this.boxDetails);
              this.addEditForm.controls['BoxesList'].setValue(this.boxDetailsReal);
              // ADDED THIS CODE TO CALCULATE THE SUM OF ALL INVOICES AND SAVE IT
              var TotalinvoiceValue = 0
              if (this.invoices.length > 0) {
                this.invoices.forEach((element) => {
                  TotalinvoiceValue += element.InvoiceValue;
                });

                this.setValuesForAddEditFormControl([
                  { name: "TotalInvoiceValue", value: TotalinvoiceValue }
                ]);
              }
              //-------------------------------------------------------------------
              this.formData.delete('FormData');
              this.formData.append('FormData', JSON.stringify(this.addEditForm.value));
              this._pageService.finalizeLCLXPCN(this.formData).subscribe(res => {
                if (res['Success']) {

                  this.getXPCNDetailsForFinalization(true);
                  this.getLCLXPCNBoxDetails();
                  this.isXPCNFinalized = false;

                }

              })
            })

          }

        }
      });
  }

  setlocationDetailsOnSave() {

    let locationDetails = [
      {
        formControlId: "ConsignorStateId",
        formControlName: "ConsignorState",
        list: this.statesListConsignor,
        listKeyName: "StateId",
        listKeyValue: "StateName",
      },
      {
        formControlId: "ConsigneeStateId",
        formControlName: "ConsigneeState",
        list: this.statesListConsignee,
        listKeyName: "StateId",
        listKeyValue: "StateName",
      },
      {
        formControlId: "ConsignorDistrictId",
        formControlName: "ConsignorDistrict",
        list: this.districtsListConsignor,
        listKeyName: "DistrictId",
        listKeyValue: "DistrictName",
      },
      {
        formControlId: "ConsigneeDistrictId",
        formControlName: "ConsigneeDistrict",
        list: this.districtsListConsignee,
        listKeyName: "DistrictId",
        listKeyValue: "DistrictName",
      },
      {
        formControlId: "ConsignorLocationId",
        formControlName: "ConsignorLocation",
        list: this.locationsListConsignor,
        listKeyName: "LocationId",
        listKeyValue: "LocationName",
      },
      {
        formControlId: "ConsigneeLocationId",
        formControlName: "ConsigneeLocation",
        list: this.locationsListConsignee,
        listKeyName: "LocationId",
        listKeyValue: "LocationName",
      },
      {
        formControlId: "ConsignorPostcodeId",
        formControlName: "ConsignorPostcode",
        list: this.postcodesListConsignor,
        listKeyName: "PostcodeId",
        listKeyValue: "PostcodeName",
      },
      {
        formControlId: "ConsigneePostcodeId",
        formControlName: "ConsigneePostcode",
        list: this.postcodesListConsignee,
        listKeyName: "PostcodeId",
        listKeyValue: "PostcodeName",
      },
    ];

    locationDetails.forEach((data) => {
      this.setFormControlNameWithId(
        data.formControlId,
        data.formControlName,
        data.list,
        data.listKeyName,
        data.listKeyValue
      );
    });

  }

  gstinValidation(): boolean {
    let consingorGTSINF = this.getValueOfAddEditFormControl("ConsignorGSTIN");
    let consigneeGSTINF = this.getValueOfAddEditFormControl("ConsigneeGSTIN");

    if (
      consingorGTSINF.length > 2 &&
      this.getValueOfAddEditFormControl("ConsignorStateId") > 0
    ) {
      let consignorGSTIN = this.statesListConsignor.filter(
        (x) =>
          x.StateId == this.getValueOfAddEditFormControl("ConsignorStateId")
      )[0]["StateGSTIN"];
      if (consingorGTSINF.substring(0, 2) != consignorGSTIN) {
        this.addEditForm.controls["ConsignorGSTIN"].markAllAsTouched();
        this.alertService.createAlert("Invalid Consingor GSTIN Number", 0);
        return false;
      }
    } else if (consingorGTSINF.length > 0 && consingorGTSINF.length != 15) {
      this.alertService.createAlert(
        "Consingnor GSTIN Should be of 15 Digits",
        0
      );
      return false;
    }

    if (
      consigneeGSTINF.length > 2 &&
      this.getValueOfAddEditFormControl("ConsigneeStateId") > 0 && consigneeGSTINF != 'URP'
    ) {
      let consigneeGSTIN = this.statesListConsignee.filter(
        (x) =>
          x.StateId == this.getValueOfAddEditFormControl("ConsigneeStateId")
      )[0]["StateGSTIN"];
      if (consigneeGSTINF.substring(0, 2) != consigneeGSTIN) {
        this.addEditForm.controls["ConsigneeGSTIN"].markAllAsTouched();
        this.alertService.createAlert("Invalid Consingnee GSTIN Number", 0);
        return false;
      }
    } else if (consigneeGSTINF.length > 0 && consigneeGSTINF.length != 15 && consigneeGSTINF != 'URP') {
      this.alertService.createAlert(
        "Consingnee GSTIN Should be of 15 Digits",
        0
      );
      return false;
    }
    return true;
  }

  postcodeChanged(val) { }

  setFormControlNameWithId(
    formControlId: string,
    formControlName: string,
    list: any[],
    listKeyName: string,
    listKeyValue: string
  ) {
    this.setValuesForAddEditFormControl([
      {
        name: formControlName,
        value:
          list.length > 0
            ? list.filter(
              (x) =>
                x[listKeyName] ==
                this.getValueOfAddEditFormControl(formControlId)
            )[0][listKeyValue]
            : null,
      },
    ]);
  }

  public onlyNumbers(event) {
    let k;
    k = event.charCode;
    return k > 47 && k < 58;
  }

  onlyAlphabetsWithSpace(event) {
    let k;
    k = event.charCode;
    return (k > 64 && k < 91) || (k > 96 && k < 123) || k == 32;
  }

  setLocationDetails(data: any[]) {
    this.getDistricts(
      this.getValueOfAddEditFormControl("ConsignorStateId"),
      this.CONSIGNOR
    );
    this.setValuesForAddEditFormControl([
      { name: "ConsignorDistrictId", value: data["ConsignorDistrictId"] },
    ]);
    this.getDistricts(
      this.getValueOfAddEditFormControl("ConsigneeStateId"),
      this.CONSIGNEE
    );
    this.setValuesForAddEditFormControl([
      { name: "ConsigneeDistrictId", value: data["ConsigneeDistrictId"] },
    ]);

    this.getLocations(
      this.getValueOfAddEditFormControl("ConsignorDistrictId"),
      this.CONSIGNOR
    );
    this.setValuesForAddEditFormControl([
      { name: "ConsignorLocationId", value: data["ConsignorLocationId"] },
    ]);
    this.getLocations(
      this.getValueOfAddEditFormControl("ConsigneeDistrictId"),
      this.CONSIGNEE
    );
    this.setValuesForAddEditFormControl([
      { name: "ConsigneeLocationId", value: data["ConsigneeLocationId"] },
    ]);

    this.getPostCodes(
      this.getValueOfAddEditFormControl("ConsignorLocationId"),
      this.CONSIGNOR
    );
    this.setValuesForAddEditFormControl([
      { name: "ConsignorPostcodeId", value: data["ConsignorPostcodeId"] },
    ]);
    this.getPostCodes(
      this.getValueOfAddEditFormControl("ConsigneeLocationId"),
      this.CONSIGNEE
    );
    this.setValuesForAddEditFormControl([
      { name: "ConsigneePostcodeId", value: data["ConsigneePostcodeId"] },
    ]);
  }

  // addBox(boxes, mode: number) {
  //   if (mode == this.MULTIPLE) {
  //     this.boxDetails = [];
  //   }

  //   if (boxes > 0 && typeof boxes != "undefined") {
  //     for (let i = 1; i <= boxes; i++) {
  //       if (mode == this.SINGLE) this.noOfBoxesAdded++;
  //       this.boxDetails.push({
  //         BoxId: null,
  //         XPCNId: this.lclXPCNId,
  //         Length: 0,
  //         Breadth: 0,
  //         Height: 0,
  //         Weight: 0,
  //         HUNumber: null,
  //         HUBarcodeUrl: null,
  //       });
  //     }
  //   }
  // }

  unitFactor = 1728;
  unitChanged(val) {
    this.unitFactor = val == "Centimeter" ? 27000 : 1728;
    for (let i = 0; i < this.boxDetails.length; i++) {
      this.calculateBoxWeight(i);
    }
  }

  calculateBoxWeight(i) {
    this.boxesChanged = true;
    if (this.boxDetails[i].Length != null && this.boxDetails[i].Breadth != null && this.boxDetails[i].Height != null && this.boxDetails[i].Pkgs != null) {
      let weight = this.cubicFeet * Number(this.boxDetails[i].Length) * Number(this.boxDetails[i].Breadth) * Number(this.boxDetails[i].Height) * Number(this.boxDetails[i].Pkgs);
      weight = weight / this.unitFactor;
      this.boxDetails[i].Weight = Math.round(weight);
      this.calculateTotalPkgsWt();
    }
  }

  // OLD FUNCTION COMMENTED
  // calculateTotalPkgsWt() {
  //   this.totalPkgs = 0;
  //   this.totalVWt = 0;
  //   this.totalWt = 0;
  //   this.boxDetails.forEach(x => {
  //     this.totalPkgs += Number(x.Pkgs);
  //     this.totalVWt += Number(x.Weight);
  //     this.totalWt += Number(x.ActWeight);
  //   });

  //   let minChargedWt = this.addEditForm.controls['MinChargedWeight'].value;
  //   this.totalVWt = Number(this.totalVWt);
  //   this.addEditForm.controls['NoOfPackages'].setValue(this.totalPkgs);
  //   this.addEditForm.controls['VolumetricWeight'].setValue(this.totalVWt);
  //   this.addEditForm.controls['Weight'].setValue(this.totalWt);

  //   var actWt = this.addEditForm.controls['Weight'].value;
  //   if (actWt == null || actWt == undefined || actWt == '')
  //     actWt = 0;

  //     this.addEditForm.controls['ChargedWeight'].setValue(Math.max(actWt, this.totalVWt, minChargedWt));

  //   // if (actWt >= this.totalVWt)
  //   //   this.addEditForm.controls['ChargedWeight'].setValue(actWt);
  //   // else
  //   //   this.addEditForm.controls['ChargedWeight'].setValue(this.totalVWt);
  // }

  calculateTotalPkgsWt() {
    this.totalPkgs = 0;
    this.totalVWt = 0;
    this.totalWt = 0;
    this.boxDetails.forEach(x => {
      this.totalPkgs += Number(x.Pkgs);
      this.totalVWt += Number(x.Weight);
      this.totalWt += Number(x.ActWeight);
    });

    this.totalWt = Number(this.totalWt.toFixed(2));

    this.noOfBoxesAdded = this.totalPkgs
    if(this.CustName == 'Qwik Supply Chain Pvt. Ltd.'){    this.setValuesForAddEditFormControl([{ name: "NoOfPackages", value: this.totalPkgs }]);  }
    // this.setValuesForAddEditFormControl([{ name: "NoOfPackages", value: this.totalPkgs }]);

    this.totalVWt = Number(this.totalVWt);

    this.addEditForm.controls['VolumetricWeight'].setValue(this.totalVWt);
    const chargedWeight: number = Number.parseInt(this.getValueOfAddEditFormControl("MinChargedWeight"))

    if (this.weightToBeConsidered == 'volumetric') {
      if (!this.preferHigherWeight) this.setValuesForAddEditFormControl([{ name: "ChargedWeight", value: this.getHighestWeight([this.totalVWt, chargedWeight]) }])
      else this.setValuesForAddEditFormControl([{ name: "ChargedWeight", value: this.getHighestWeight([this.totalVWt, chargedWeight, this.getValueOfAddEditFormControl("Weight")]) }])
    }

    //debugger
    // ADDED TO SET CHARGED WEIGHT TO MAX CHARGED WEIGHT IF IT EXCEEDS IT - Yathish
    if ((this.getValueOfAddEditFormControl("ChargedWeight") > this.getValueOfAddEditFormControl("MaxChargeableWeight")) 
    && this.getValueOfAddEditFormControl("MaxCWAppOnXPCN") == true && this.getValueOfAddEditFormControl("MinChargeableType") == 'weight') {

      this.setValuesForAddEditFormControl([{ name: "ChargedWeight", value: this.getValueOfAddEditFormControl("MaxChargeableWeight") }])

    }

  }

  getHighestWeight(weights: number[]): number {
    return Math.max(...weights);
  }

  
  calculateActualWeight() {
    const weight = Number(this.boxDetails.reduce((acc, cur) => acc + Number.parseFloat(cur.ActWeight), 0).toFixed(2))
    const chargedWeight: number = Number.parseInt(this.getValueOfAddEditFormControl("MinChargedWeight"))

    this.setValuesForAddEditFormControl([{ name: "Weight", value: weight }])

    if (this.weightToBeConsidered == 'actual') this.setValuesForAddEditFormControl([{ name: "ChargedWeight", value: this.getHighestWeight([weight, chargedWeight]) }])
    else if (this.weightToBeConsidered == 'volumetric' && this.preferHigherWeight) {
      this.setValuesForAddEditFormControl([{ name: "ChargedWeight", value: this.getHighestWeight([this.totalVWt, chargedWeight, this.getValueOfAddEditFormControl("Weight")]) }])
    }

    //debugger
    // ADDED TO SET CHARGED WEIGHT TO MAX CHARGED WEIGHT IF IT EXCEEDS IT - Yathish
    if ((this.getValueOfAddEditFormControl("ChargedWeight") > this.getValueOfAddEditFormControl("MaxChargeableWeight")) 
    && this.getValueOfAddEditFormControl("MaxCWAppOnXPCN") == true && this.getValueOfAddEditFormControl("MinChargeableType") == 'weight') {

      this.setValuesForAddEditFormControl([{ name: "ChargedWeight", value: this.getValueOfAddEditFormControl("MaxChargeableWeight") }])
      
    }

  }


  addBox(boxes, mode: number) {
    this.boxesChanged = true;
    if (mode == this.MULTIPLE) {
      this.boxDetails = []
    }


    if (boxes > 0 && typeof (boxes) != "undefined") {
      for (let i = 1; i <= boxes; i++) {
        if (mode == this.SINGLE) this.noOfBoxesAdded++;
        this.boxDetails.push({ BoxId: null, XPCNId: this.lclXPCNId, Length: 0, Breadth: 0, Height: 0, Pkgs: 1, ActWeight: 0, Weight: 0, HUNumber: null, HUBarcodeUrl: null })
      }
    }
    this.calculateTotalPkgsWt();
  }

  // deleteBox(index) {
  //   if (this.boxDetails[index].BoxId == null) {
  //     this.boxDetails.splice(index, 1);
  //     this.noOfBoxesAdded -= 1;
  //   }
  // }

  deleteBox(index) {
    this.boxesChanged = true;
    if (this.boxDetails[index].BoxId != null) {
      this._pageService.deleteXPCNBoxesConsignor(this.boxDetails[index].BoxId).subscribe(data => {

      });
    }
    this.boxDetails.splice(index, 1);
    this.noOfBoxesAdded -= 1;
    this.calculateActualWeight();
    this.calculateTotalPkgsWt();
  }

  saveBoxes() {
    this.isSaveBoxesClicked = true;
    if (this.boxDetails.length > 0) {
      this._pageService
        .upsertLCLOrderXPCNBoxes({ LCLXPCNBoxes: this.boxDetails })
        .subscribe((res) => {
          this.isSaveBoxesClicked = false;
          let responseStatus = res["Success"];
          this.alertService.createAlert(
            res["Message"],
            responseStatus == true ? 1 : 0
          );
          if (responseStatus) {
            this.getLCLXPCNBoxDetails();
          }
        });
    } else {
      this.alertService.createAlert("Please add box details", 0);
      this.isSaveBoxesClicked = false;
    }
  }

  inputBox() {
    this.isValueEntered = true;
  }

  getLCLXPCNBoxDetails() {
    this._pageService
      .getLCLOrderXPCNBoxes(this.lclXPCNId)
      .subscribe((res) => {
        this.boxDetailsReal = res['Data'];
        // this.boxDetails = res["Data"];
        // //console.log(this.boxDetails, "this.boxDetails--")
        // this.boxesFromBackend = this.boxDetails.length;
        // this.noOfBoxesAdded = this.boxDetails.length;
        // //this.setValuesForAddEditFormControl([{ name: "UsedStickerNo", value: null }]);
        // this.boxDetailsArr = this.boxDetails.filter(
        //   (x) => x.isSelected === true
        // );
        // this.addEditForm.controls["BoxesList"].setValue(this.boxDetails);
        // const count = this.boxDetails.filter(
        //   (item) => item.isSelected === true
        // ).length;
        // if (count > 0) {
        //   this.setValuesForAddEditFormControl([
        //     { name: "UsedStickerNo", value: count },
        //   ]);
        // } else {
        //   this.setValuesForAddEditFormControl([
        //     { name: "UsedStickerNo", value: null },
        //   ]);
        // }
        // this.ValidateEnterBoxes(this.FROM_INPUT);
      });
  }

  onNavigate(value) {
    window.open(
      "https://xpindiadocuments.s3.ap-south-1.amazonaws.com/" +
      value.HUBarcodeUrl,
      "_blank"
    );
  }

  VACSelectionChange(event) {
    this.addEditForm.controls["isVAC"].setValue(event);
    this.isValueAddedCharges = event;
    if (event) {
      this.setAddEditFormValidator("VACRemarkId");
    } else {
      this.clearAddEditFormValidator("VACRemarkId", true);

      this.setValuesForAddEditFormControl([
        { name: "ValueAddedCharge", value: 0 }
      ]);

    }

    this.resetVACValues();
  }

  VACRemarkSelectionChange(event) {
    let selectedRemark = this.vacRemarksList.filter(
      (x) => x.id == event.value
    )[0];
    this.setValuesForAddEditFormControl([
      { name: "VACRemarkName", value: selectedRemark.name },
    ]);
    //debugger
    if (selectedRemark.name === "COD" || selectedRemark.name === "DOD") {
      this.isCODDODCheckBoxEnabled = true;
      this.isBOD_OR_DODChecked({checked:true})
      this.setAddEditFormValidator("AmountToCollect");
      this.showAppointmentDate = false
    }
    else if (selectedRemark.name === "Appointment Delivery") {
      this.showAppointmentDate = true
      this.isCODDODCheckBoxEnabled = false;
      this.isBOD_OR_DODChecked({checked:false})
      this.clearAddEditFormValidator("AmountToCollect", true);
    }
    else {
      this.isCODDODCheckBoxEnabled = false;
      this.isBOD_OR_DODChecked({checked:false})
      this.clearAddEditFormValidator("AmountToCollect", true);
      this.showAppointmentDate = false
    }

    //COMMENTED FOR COD/DOD FUNCTIONALITY
    //this.isCODORDODChecked = true;
    this.setValuesForAddEditFormControl([
      { name: "ValueAddedCharge", value: selectedRemark.amount }
      //COMMENTED FOR COD/DOD FUNCTIONALITY
      //{ name: "CODBODChecked", value: this.isCODORDODChecked },
    ]);
    //COMMENTED FOR COD/DOD FUNCTIONALITY
    //this.clearAddEditFormValidator("AmountToCollect", true);
    this.calculateTotal(null);
  }

  isBOD_OR_DODChecked(event) {
    //debugger
    this.isCODORDODChecked = event.checked;
    if (!this.isCODORDODChecked)
      this.setAddEditFormValidator("AmountToCollect");
    else {
      this.clearAddEditFormValidator("AmountToCollect", true);
    }

    // ADDED TO SAVE THE COD DOD AMOUNT WHEN SAME AS INVOICE IS CHECKED - YATHISH 24 July 2024
    var invoiceValue = 0
    if(this.invoices.length > 0 && this.isCODORDODChecked){
      this.invoices.forEach((element) => {
        invoiceValue += element.InvoiceValue;
      });

      this.setValuesForAddEditFormControl([
        { name: "AmountToCollect", value: invoiceValue }
      ]);
    }
    //------------------------------------------------------------------

    this.setValuesForAddEditFormControl([
      { name: "CODBODChecked", value: event.checked },
    ]);
    //console.log('checked',this.getValueOfAddEditFormControl('CODBODChecked'),'amount',this.getValueOfAddEditFormControl('AmountToCollect'))
  }

  resetVACValues() {
    this.calculateTotal();
    this.isCODDODCheckBoxEnabled = false;
    this.isCODORDODChecked = true;
    this.setValuesForAddEditFormControl([
      { name: "CODBODChecked", value: this.isCODORDODChecked },
      { name: "VACRemarkId", value: null },
      { name: "VACRemarkName", value: null },
      { name: "ValueAddedCharge", value: "" },
    ]);
  }

  setVACIfExists(data) {
    // debugger
    this.addEditForm.controls["isVAC"].setValue(data["isVAC"]);
    this.isValueAddedCharges = this.addEditForm.controls["isVAC"].value;
    if (this.isValueAddedCharges) {
      //If VAC Charges are Yes

      this.addEditForm.controls["VACRemarkId"].setValue(data["VACRemarkId"]);
      this.addEditForm.controls["VACRemarkName"].setValue(
        data["VACRemarkName"]
      );
      this.addEditForm.controls["ValueAddedCharge"].setValue(
        data["ValueAddedCharge"]
      );
      //If VACRemarkId  is  null it means the VAC reason is  COD or DOD and vice-versa
      if (this.addEditForm.controls["VACRemarkId"].value == null) {
        let vacRemarkName = this.addEditForm.controls["VACRemarkName"].value;
        if (vacRemarkName == "COD" || vacRemarkName == "DOD") {
          this.isCODDODCheckBoxEnabled = true; //As it is COD or DOD, showing check box to the user
          if (vacRemarkName === "COD") {
            if (data["CustomerVASType"] === 'custom') {
              const control = this.vacRemarksList.filter(x=>x.name === "COD")
              const id = control.length > 0 ? control[0].id : null
              this.addEditForm.controls["VACRemarkId"].setValue(id);
            }
            else {
              this.addEditForm.controls["VACRemarkId"].setValue(-3);
            }
          } else if (vacRemarkName === "DOD") {
            if (data["CustomerVASType"] === 'custom') {
              const control = this.vacRemarksList.filter(x=>x.name === "DOD")
              const id = control.length > 0 ? control[0].id : null
              this.addEditForm.controls["VACRemarkId"].setValue(id);
            }
            else {
              this.addEditForm.controls["VACRemarkId"].setValue(-4);
            }
          }

          let amountToCollect = data["AmountToCollect"];
          if (amountToCollect != null) {
            this.addEditForm.controls["AmountToCollect"].setValue(
              amountToCollect
            );
          }

          /*COMMENTED THIS CODE TO SET TOTAL INVOICE VALUE WHILE SAME AS INVOICE CHECKED - YATHISH */

          // if (amountToCollect == null) {
          //   this.isCODORDODChecked = true; //As AmountToCollect is null, it means checkbox is checked and no need to show input box to the user
          //   this.addEditForm.controls["CODBODChecked"].setValue(
          //     this.isCODORDODChecked
          //   );
          // } else {
          //   //As AmountToCollect is not null, it means checkbox is not checked and user has entered some value in vacAmount input box
          //   this.isCODORDODChecked = false;
          //   this.addEditForm.controls["CODBODChecked"].setValue(
          //     this.isCODORDODChecked
          //   );
          //   this.addEditForm.controls["AmountToCollect"].setValue(
          //     amountToCollect
          //   );
          // }
        }
      }
    }
  }

  openLCLXPCNPreview() {
    this.dialog.open(XpcnPreviewDialogComponent, {
      data: {
        xpcn: {
          XPCNId:this.lclXPCNId,
          IsFinalized : this.isXPCNFinalized
        }
      },
      height: "auto",
      width: "900px",
      autoFocus: false,
    });
  }

  ConsigneeDetails: any[] = [];
  ConsigneeDetailsFilt: any[] = [];
  GetAllConsignee() {
    this._pageService.getConsigneeDetails().subscribe((data) => {
      if (data["Success"]) {
        // console.log(data);
        this.ConsigneeDetails = data["Data"];
        this.ConsigneeDetailsFilt = data["Data"];
      }
    });
  }

  FilterConsigneeDetails(value) {
    if (value === "") {
      this.ConsigneeDetailsFilt = this.ConsigneeDetails;
      return;
    }
    const filterValue = value.toLowerCase();
    this.ConsigneeDetailsFilt = this.ConsigneeDetails.filter((option) =>
      option.LookupName.toLowerCase().includes(filterValue)
    );
  }

  FilterConsignee(value) {
    this._pageService.getConsigneeDetailsbyId(value).subscribe((data) => {
      if (data["Data"] != null) {
        this.setValuesForAddEditFormControl([
          { name: "ConsigneeMobile", value: data["Data"][0].Mobile },
          { name: "ConsigneeEmail", value: data["Data"][0].Email },
          { name: "ConsigneeStateId", value: data["Data"][0].StateId },
          { name: "ConsigneeDistrictId", value: data["Data"][0].DistrictId },
          { name: "ConsigneeLocationId", value: data["Data"][0].LocationId },
          { name: "ConsigneePostcodeId", value: data["Data"][0].PostcodeId },
          { name: "ConsigneeGSTIN", value: data["Data"][0].GSTIN },
          { name: "ConsigneeAddress", value: data["Data"][0].Address },
          { name: "ConsigneeName", value: data["Data"][0].ConsigneeName },
          { name: "NameOfConsignee", value: data["Data"][0].ConsigneeName },
          { name: "URPChecked", value: false},
        ]);

        this.getDistricts(data["Data"][0].StateId, this.CONSIGNEE);
        this.CheckGatewayAvailabiity(
          data["Data"][0].DistrictId,
          this.CONSIGNEE,
          this.lclXPCNId
        );
        this.getPostCodes(data["Data"][0].LocationId, this.CONSIGNEE);
      }
    });
  }

  CheckGatewayAvailabiity(DistrictId: any, Type: any, xpcnId: any) {
    if (this.checkServiceType !== "LCL Direct - Delivery") {
      this._pageService
        .CheckGatewayForDistrict(DistrictId, xpcnId)
        .subscribe((res) => {
          if (res["Success"]) {
            this.getLocations(DistrictId, Type);
          }
        });
    } else {
      this.getLocations(DistrictId, Type);
    }
  }

  EnteredUsedSticker: number;
  FROM_INPUT: number = 1;
  METHOD_CALL: number = 2;
  ValidateEnterBoxes(actionType: number) {
    this.EnteredUsedSticker =
      this.getValueOfAddEditFormControl("UsedStickerNo");
    if (this.helperBoxDetails.length == 0 || actionType == this.FROM_INPUT) {
      this.helperBoxDetails = this.boxDetails;
      if (this.EnteredUsedSticker > this.noOfBoxesAdded) {
        this.alertService.createAlert(
          "Entered sticker Quantity is greater than stickers,Please enter correct input",
          0
        );
        this.setValuesForAddEditFormControl([
          { name: "UsedStickerNo", value: null },
        ]);
        this.InputRequred = true;
      } else {
        this.InputRequred = false;
        this.setValuesForAddEditFormControl([
          { name: "UsedStickerNo", value: this.EnteredUsedSticker },
        ]);

        for (var i = 0; i < this.boxDetails.length; i++) {
          if (i < this.addEditForm.controls["UsedStickerNo"].value) {
            this.boxDetails[i].isSelected = true;
          } else {
            this.boxDetails[i].isSelected = false;
          }
        }
        this.boxDetailsArr = this.boxDetails.filter((x) => x.isSelected);
        this.isBoxesCheckBox = true;
        this.addEditForm.controls["BoxesList"].setValue(this.boxDetails);
      }
    } else {
      this.boxDetails = this.helperBoxDetails;
    }

    //console.log(this.addEditForm.controls['BoxesList'].value);
  }

  boxDetailsArr: any[] = [];
  checkboxChanged() {
    this.isBoxesCheckBox = false;
    this.boxDetailsArr = this.boxDetails.filter((x) => x.isSelected);
    if (
      this.addEditForm.controls["UsedStickerNo"].value ==
      this.boxDetailsArr.length
    ) {
      this.isBoxesCheckBox = true;
    } else {
      this.isBoxesCheckBox = false;
    }
    this.addEditForm.controls["BoxesList"].setValue(this.boxDetails);
    //console.log(this.addEditForm.controls['BoxesList'].value);
  }

  backto() {
    this._router.navigateByUrl(
      "/xp/generate-xpcn"
    );
  }

  invValChanged(checked) {
    if (checked == true) {
      this.invValLessThan50 = true;
      // if (this.invoices[0].InvoiceValue > 50000) {
      //   this.addEditForm.controls['EWBNo'].setValue(null);
      //   this.addEditForm.controls['EWBExpDate'].setValue(null);
      //   this.addEditForm.controls['InvNo'].setValue(null);
      //   this.addEditForm.controls['InvDate'].setValue(null);
      //   this.addEditForm.controls['InvAmt'].setValue(null);
      //   this.invTotal = 0;
      // }
    } else {
      this.invValLessThan50 = false;
    }
  }

  lrChanged(val) {
    // if (val != null && val != '' && val != undefined) {
    //   this.addEditForm.controls["ASNNo"].setValue(null);
    //   this.addEditForm.controls["ASNNo"].disable();
    // } else {
    //   this.addEditForm.controls["ASNNo"].enable();
    // }
  }

  asnChanged(val) {
    // if (val != null && val != '' && val != undefined) {
    //   this.addEditForm.controls["LR_PO"].setValue(null);
    //   this.addEditForm.controls["LR_PO"].disable();
    // } else {
    //   this.addEditForm.controls["LR_PO"].enable();
    // }
  }

  DisableFormControls(formControls: { name: string }[]) {
    /*If fromControl keys are more than 1 then forEach executed,
     if not no need of forEach execution*/
    if (formControls.length > 1) {
      formControls.forEach((formControl) => {
        this.addEditForm.controls[formControl.name].disable();
      });
    }
    else {
      this.addEditForm.controls[formControls[0].name].disable()
    }
  }

  EnableFormControls(formControls: { name: string }[]) {
    /*If fromControl keys are more than 1 then forEach executed,
     if not no need of forEach execution*/
    if (formControls.length > 1) {
      formControls.forEach((formControl) => {
        this.addEditForm.controls[formControl.name].enable();
      });
    }
    else {
      this.addEditForm.controls[formControls[0].name].enable()
    }
  }

  disableFinalizedXPCN() {
    let formControls = [
      { name: "Unit" },
      { name: "XPCNCode" },
      { name: "ASNNo" },
      { name: "strOrderDate" },
      { name: "VehicleNo" },
      { name: "From" },
      { name: "OrderIdCode" },
      { name: "NameOfConsignor" },
      { name: "ConsignorGSTIN" },
      { name: "ConsignorAddress" },
      { name: "NameOfConsignee" },
      { name: "ConsigneeGSTIN" },
      { name: "ConsigneeAddress" },
      { name: "ConsigneeId" },
      { name: "ConsignorName" },
      { name: "ConsigneeName" },
      { name: "ConsignorStateId" },
      { name: "ConsignorState" },
      { name: "ConsigneeStateId" },
      { name: "ConsigneeState" },
      { name: "ConsignorMobile" },
      { name: "ConsignorEmail" },
      { name: "ConsigneeMobile" },
      { name: "ConsigneeEmail" },
      { name: "GoodsDescId" },
      { name: "NoOfPackages" },
      { name: "Quantity" },
      { name: "Weight" },
      { name: "VolumetricWeight" },
      { name: "InvValueBelow50k" },


      { name: "CNCharges" },
      { name: "FOVCharge" },
      { name: "RiskCharges" },
      { name: "ODACharge" },
      { name: "ToPayCharge" },
      { name: "FuelCharge" },
      { name: "GreenCharge" },
      { name: "HandlingCharges" },
      { name: "CODDODCharge" },
      { name: "DelCharge" },
      { name: "TotalCharge" },


      {
        name: "RiskTypeId"
      },
      { name: "ModeOfFreightId" },
      { name: "IsFinalized" },
      { name: "PackageTypeId" },
      {
        name: "ChargesPaidByFFV"
      },
      { name: "SpecialRemarks" },
      { name: "BasicFreight" },
      { name: "OtherCharges" },
      { name: "ChargedWeight" },
      { name: "LR_PO" },
      { name: "isVAC" },
      { name: "ConsignorDistrictId" },
      { name: "ConsignorPostcodeId" },
      { name: "ConsignorLocationId" },

      { name: "ConsigneeDistrictId" },
      { name: "ConsigneePostcodeId" },
      { name: "ConsigneeLocationId" },
      { name: "AppointmentDate" },
      { name: "VACRemarkId" }

    ];

    this.DisableFormControls(formControls)

  }

  isURPChecked(event) {
    if (event.checked) {
      this.addEditForm.controls['ConsigneeGSTIN'].disable()
      this.setValuesForAddEditFormControl([
        { name: "URPChecked", value: event.checked },
        { name: "ConsigneeGSTIN", value: "URP" }
      ]);
    }
    else {
      this.addEditForm.controls['ConsigneeGSTIN'].enable()
      this.setValuesForAddEditFormControl([
        { name: "URPChecked", value: event.checked },
        { name: "ConsigneeGSTIN", value: null }
      ]);
    }
  }

  ValidateEnteredGISTIN() {
    if(this.getValueOfAddEditFormControl('ConsigneeGSTIN') != 'URP')
    {this.setValuesForAddEditFormControl([{name: 'URPChecked' , value: false}]);} else {this.setValuesForAddEditFormControl([{name: 'URPChecked' , value: true}]);}
    if (this.getValueOfAddEditFormControl('ConsigneeGSTIN') != null && this.getValueOfAddEditFormControl('ConsigneeGSTIN').length == 15) {
      this._pageService.getCustomerDetailsfromGSTINBilling(this.getValueOfAddEditFormControl('ConsigneeGSTIN')).subscribe(data => {
        if (data != null) {
          if ((data['Data'].CustomerName != null || data['Data'].CustomerName != '') && this.getValueOfAddEditFormControl('ConsigneeStateId') == data['Data'].StateId ) {
            this.alertService.createAlert(" Consignee GSTIN Valid", 1);
          }
          else{
            this.setValuesForAddEditFormControl([{name: 'ConsigneeGSTIN' , value: null}]);
            this.alertService.createAlert("Consignee GSTIN not valid, Please select URP if applicable.", 0);
          }
        }
      });
    }
  }


  disableFreightFields(){
    let formControls = [
      
      { name: "BasicFreight" },
      { name: "CNCharges" },
      { name: "FOVCharge" },
      { name: "RiskCharges" },
      { name: "ODACharge" },
      { name: "ToPayCharge" },
      { name: "FuelCharge" },
      { name: "GreenCharge" },
      { name: "HandlingCharges" },
      { name: "CODDODCharge" },
      { name: "DelCharge" },
      { name: "OtherCharges" },
      { name: "TotalCharge" }
    ]
    this.DisableFormControls(formControls)
  }

  enableFreightFields(){
    let formControls = [
      
      { name: "BasicFreight" },
      { name: "CNCharges" },
      { name: "FOVCharge" },
      { name: "RiskCharges" },
      { name: "ODACharge" },
      { name: "ToPayCharge" },
      { name: "FuelCharge" },
      { name: "GreenCharge" },
      { name: "HandlingCharges" },
      { name: "CODDODCharge" },
      { name: "DelCharge" },
      { name: "OtherCharges" },
      { name: "TotalCharge" }
    ]
    this.EnableFormControls(formControls)
  }

  ToPayServiceCharge:number = 0
  rateFetched: boolean = false
  GetFreightDetails() {

    var totalInvoiceValue = this.invoices.reduce((sum, invoice) => sum + invoice.InvoiceValue, 0);
    var ServiceCharge = this.freightModes.filter(x => x.LookupId == this.getValueOfAddEditFormControl("ModeOfFreightId"))[0] != null ?
      this.freightModes.filter(x => x.LookupId == this.getValueOfAddEditFormControl("ModeOfFreightId"))[0].LookupName : null

    //debugger

    let data = {
      int_customer_id: this.CustomerID,
      vc_service_type: this.checkServiceType,

      vc_from_district: (this.consignorDistrictID != null
        && this.districtsListConsignor.filter((x) => x.DistrictId == this.consignorDistrictID).length > 0)
        ? this.districtsListConsignor.filter((x) => x.DistrictId == this.consignorDistrictID)[0].DistrictName
        : null,

      vc_to_district: (this.consigneeDistrictID != null
        && this.districtsListConsignee.filter((x) => x.DistrictId == this.consigneeDistrictID).length > 0)
        ? this.districtsListConsignee.filter((x) => x.DistrictId == this.consigneeDistrictID)[0].DistrictName
        : null,

      vc_from_state: this.getValueOfAddEditFormControl('ConsignorState'),
      vc_to_state: this.getValueOfAddEditFormControl('ConsigneeState'),
      vc_from_region: null,
      vc_to_region: null,
      vc_from_location: this.getValueOfAddEditFormControl('From'),
      vc_to_location: this.getValueOfAddEditFormControl('To'),

      ChargedWeight: this.getValueOfAddEditFormControl('ChargedWeight'),
      TotalInvoiceValue: totalInvoiceValue,
      NoOfPackages: this.getValueOfAddEditFormControl('NoOfPackages'),
      NoOfBoxes: this.totalPkgs,

      MinChargeableType: this.getValueOfAddEditFormControl('MinChargeableType'),
      MinChargeableFreight: this.getValueOfAddEditFormControl('MinChargeableFreight'),
      ServiceCharge: ServiceCharge
    }

    this._pageService.getFreight(data).subscribe(res => {
      //debugger
      if (res != null) {
        this.setValuesForAddEditFormControl([
          { name: 'BasicFreight', value: res['Data']['BaseFreight'] },
          { name: 'FOVCharge', value: res['Data']['FOVCharge'] },
          { name: 'RiskCharges', value: res['Data']['RiskCharge'] },
          { name: 'ODACharge', value: res['Data']['ODACharge'] },
          //{ name: 'ToPayCharge', value: res['Data']['ToPayServiceCharge'] },
          { name: 'FuelCharge', value: res['Data']['FuelSurcharge'] },
          { name: 'GreenCharge', value: res['Data']['GreenTax'] },
          { name: 'HandlingCharges', value: res['Data']['HandlingCharge'] },
          { name: 'OtherCharges', value: res['Data']['OtherCharges'] }
        ]);

        let fs = (this.calculateFuelSurcharge(res['Data']['FuelSurchargeApplicableOn'], res['Data']['RateType'])/100) * res['Data']['FuelSurcharge']

        this.setValuesForAddEditFormControl([
          { name: 'FuelCharge', value: fs },
        ]);

        this.ToPayServiceCharge = res['Data']['ToPayServiceCharge']
        var isToPayServiceCharge = this.freightModes.filter(x => x.LookupId == this.getValueOfAddEditFormControl("ModeOfFreightId"))[0].LookupName
        if (isToPayServiceCharge == 'To Pay') {
          this.setValuesForAddEditFormControl([{ name: "ToPayCharge", value: res['Data']['ToPayServiceCharge'].toFixed(2) }])
        }

        this.disableFreightFields()
      }
      else{
        
      }
      this.rateFetched = true
      this.setValuesForAddEditFormControl([{ name: "CNCharges", value: this.data.CustomerCNCharges }])
    });
  }

  fuelsurchargeApplicableOn: any
  calculateFuelSurcharge(FuelSurchargeApplicableOn,rateType): number {
    this.fuelsurchargeApplicableOn = ''
    var fuelsurcharge = 0

    if (FuelSurchargeApplicableOn != null && FuelSurchargeApplicableOn.length > 0) {
      FuelSurchargeApplicableOn.forEach(element => {
        var formcontrolname = this.fuelSurchargeApplicableTypes.filter(x => x.LookupId == element).length > 0
          ? this.fuelSurchargeApplicableTypes.filter(x => x.LookupId == element)[0]
          : null;

        var value = 0
        var names = ''
        if (formcontrolname != null && formcontrolname != undefined) {
          // value = this.checkServiceType === "LCL Breakbulk" && rateType === "Rate Per Vehicle Type" && formcontrolname.Name === "BasicFreight" ? this.BaseFreightForBBFuelSurcharge : parseInt(this.getValueOfAddEditFormControl(formcontrolname.Name));
          value = parseInt(this.getValueOfAddEditFormControl(formcontrolname.Name));
          names = formcontrolname.LookupName

          this.fuelsurchargeApplicableOn += names + ','
        }

        fuelsurcharge += value

      });

      this.setValuesForAddEditFormControl([{ name: 'fuelsurchargeApplicableOn', value: this.fuelsurchargeApplicableOn }])
    }

    return fuelsurcharge
  }

  
  hasDuplicateDimensions(boxes: any[]): boolean {
    const dimensionsSet = new Set();

    for (let box of boxes) {
      const dimensionsKey = `${box.Length}-${box.Breadth}-${box.Height}`;
      if (dimensionsSet.has(dimensionsKey)) {
        return true; // Duplicate found
      }
      dimensionsSet.add(dimensionsKey);
    }
    return false; // No duplicates
  }


}
