import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/shared/services/alert.service';
import { TextValidator } from '../../customers/text_validator';
import { PagesService } from '../../pages.service';

@Component({
  selector: 'app-add-ewaybill-dialog',
  templateUrl: './add-ewaybill-dialog.component.html',
  styleUrls: ['./add-ewaybill-dialog.component.scss']
})
export class AddEwaybillDialogComponent implements OnInit {


  today = new Date();
  minday = new Date();
  formData: FormData = new FormData();
  isEwayBillAdded : boolean = false
  addEditForm:FormGroup;

  isEdit = true;
  constructor(
    public dialogRef:MatDialogRef<AddEwaybillDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertService,
    private _pagesService: PagesService,
    private _datePipe: DatePipe,
    public fb:FormBuilder
  ) { 
    this.addEditForm = this.fb.group({
      XPCNId: new FormControl(''),
      XPLSId: new FormControl(''),
      InvoiceId: new FormControl(''),
      strOrderDate: new FormControl(''),
      InvoiceNo: new FormControl('', Validators.compose([Validators.required])),
      Date: new FormControl('', Validators.compose([Validators.required])),
      ExpDate: new FormControl('',Validators.compose([Validators.required])),
      InvoiceValue: new FormControl('', Validators.compose([Validators.required])),
      DocUrl: new FormControl(''),
      Doc: new FormControl('')
    });
    this.today.setDate(this.today.getDate());
    this.minday.setDate(this.today.getDate() - 3);
  }

  ngOnInit() {
    this.addEditForm.controls['XPCNId'].setValue(this.data['xpcnId']);
    this.addEditForm.controls['XPLSId'].setValue(this.data['xplsId']);
    this.addEditForm.controls['strOrderDate'].setValue(this.data['orderDate']);
    if(this.data['wayBill'] == null) {
      this.isEdit = false;
    } else {     
      this.addEditForm.controls['InvoiceId'].setValue(this.data['wayBill']['InvoiceId']);
      this.addEditForm.controls['InvoiceNo'].setValue(this.data['wayBill']['InvoiceNo']);
      this.addEditForm.controls['Date'].setValue(this.data['wayBill']['Date']);
      this.addEditForm.controls['ExpDate'].setValue(this.data['wayBill']['ExpDate']);
      this.addEditForm.controls['InvoiceValue'].setValue(this.data['wayBill']['InvoiceValue']);
      this.addEditForm.controls['DocUrl'].setValue(this.data['wayBill']['DocUrl']);
    }
  }
  close():void{
    this.dialogRef.close({isEwayBillAdded : this.isEwayBillAdded})
  }
  
  handleFileInput(files: FileList) {
    if (files != null && files.length > 0) {
      const acceptedFileTypes = ['application/pdf'];
      // const acceptedFileTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      //   'application/vnd.ms-excel', 'image/gif', 'image/jpeg', 'application/pdf', 'image/x-eps',
      // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/png'];
      if (acceptedFileTypes.includes(files.item(0).type)) {
        if (files.item(0).size <= 10000000) {
          this.formData.append('123', files.item(0));
        } else {
          this.alertService.createAlert('File size must be less than 10 MB', 0);
        }
      }
      else {
        this.alertService.createAlert('Wrong File Format', 0);
        this.addEditForm.controls['Doc'].setValue(null);
        return;
      }
    }
  }

  priceValidator(event) {
    return TextValidator.priceValidator(event);
  }

  dateChanged() {
    this.addEditForm.controls['ExpDate'].setValue(null);
  }

  saveData() {
    if (this.addEditForm.valid) {
  
      let startDate = new Date(this._datePipe.transform(this.addEditForm.controls['Date'].value, 'yyyy-MM-dd'));
      let expDate = new Date(this._datePipe.transform(this.addEditForm.controls['ExpDate'].value, 'yyyy-MM-dd'));
      // if (startDate < placementDate) {
      //   this.alertService.createAlert('Date should be grater than or equal to Placement Date', 0);
      // } else 
      if(this.addEditForm.controls['InvoiceValue'].value > 0){
        if (startDate > expDate) {
          this.alertService.createAlert('Date should be less than or equal to expiry date', 0);
        } else {
          this.addEditForm.controls['Date'].setValue(startDate);
          this.addEditForm.controls['ExpDate'].setValue(expDate);
          this.formData.delete('FormData');
          this.formData.append('FormData', JSON.stringify(this.addEditForm.value));
          this._pagesService.saveLCLXPCNWaybill(this.formData).subscribe(data => {
            if (data != null) {
              if (data['Success'] != null) {
                if (data['Success']) {
                  this.isEwayBillAdded = true
                  this.close();
                } 
              }
            }
          });
        }
      }
      else{
        this.alertService.createAlert('EwayBill value should be greater than 0', 0);
      }

    } else {
      this.addEditForm.controls['InvoiceNo'].markAsTouched();
      this.addEditForm.controls['Date'].markAsTouched();
      this.addEditForm.controls['ExpDate'].markAsTouched();
      this.addEditForm.controls['InvoiceValue'].markAsTouched();
    }
  }
}
